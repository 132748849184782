import CategoryDetails from '@components/categoryDetails';
import { checkIsFJFeatureAllowed } from '@components/common';
import BannerSection from '@components/common/bannerSection';
import Breadcrumb from '@components/common/breadcrumb';
import {
  allowedSectionsToHide,
  unlockBannerImages,
} from '@components/common/constant';
import {
  addJobsCountTraits,
  jobSearchResultTrackEvent,
} from '@components/common/event-tracker';
import HeaderSection from '@components/common/headSection';
import InfoPopOver from '@components/common/infoPopover';
import PaginationComponent from '@components/common/pagination';
import FreeRemoteJobsList from '@components/freeRemoteJobsList';
import JobCard from '@components/jobCard';
import JobDetails from '@components/jobDetails';
import JobsAction from '@components/jobsAction/jobAction';
import { FlexLayout } from '@components/layout';
import { StyledContainerMd } from '@components/layout/styled';
import EmptySearchResult from '@components/remoteJob/noSearchResult';
import SearchTipsFilter from '@components/remoteJob/searchTipsFilter';
import {
  CountryResponseProps,
  ICompanies,
  IDeviceType,
  IJob,
  JobDetailsResults,
  SubCategories,
  SuccessStory,
} from '@components/shared.types';
import { Button } from '@license-admin/boldfjcomponents';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import {
  getCompanyDetails,
  getJobDetails,
  getLocalizationFunction,
  jobViewed,
} from 'API/jobs';
import { isRCPortal } from 'helper';
import { getGuidValue, getSearchType } from 'helper/eventTrackingUtils';
import {
  Action,
  USER_TRAITS_JOBS_COUNT,
  ViewType,
} from 'helper/eventTrackingUtils/constant';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import {
  BreadcrumbOuterWrapper,
  BreadcrumbWrapper,
  BtnWrap,
  CompaniesHeading,
  CompaniesSubHeading,
  JobCardWrapper,
  JobCountWrapper,
  NoResultWrap,
  PopUpwrapper,
  PromoContent,
  PromoHead,
  PromoHeadSub,
  PromoRocketCta,
  RocketImage,
  RocketImageWrap,
  SubJobCardWrapper,
  ViewMoreBtn,
  Wrapper,
} from './styled';

interface IProps {
  selectedCountry?: string;
  categorizedJobs?: any;
  isForCategoryWithLocation?: boolean;
  skipSubCategoryTitle?: boolean;
  isCategoryJobsPage?: boolean;
  categoryTitle?: any;
  pageName?: string;
  isPremiumUser: boolean;
  showJobsCount?: boolean;
  localization: any;
  jobsData: any;
  savedJobsIdsList?: any;
  location?: any;
  isLoggedIn?: any;
  sectionsToHide?: any;
  jobFilters?: any;
  widgetsName?: any;
  newsData?: any;
  successStoriesList?: any;
  categoryDetails?: any;
  queryParamsByOrder?: any;
  jobSubCategories?: SubCategories;
  faqData?: any;
  categorySuccessStories?: [SuccessStory];
  mobileView?: boolean;
  searchedJobTitle?: string;
  selectedFilters?: { [key: string]: Array<string> };
  isForLocation?: boolean;
  pageTitle?: string;
  pageLocation?: string;
  breadCrumbName?: string;
  breadCrumbNameValue?: Array<string>;
  breadcrumbDropdown?: any;
  showWorkAnywhereInUsCheckbox?: boolean;
  showWorkAnywhereInWorldCheckbox?: boolean;
  isCategorySubCategory?: boolean;
  deviceType?: IDeviceType;
  moreDropdownText?: string;
  moreJobs?: any;
  tabsToHide?: string[];
  countyList?: CountryResponseProps[];
  openSearchOnLocationChange?: boolean;
  showAllDropFilters?: boolean;
  isSearchPage?: boolean;
  saveSeachButtonState?: string;
  guid?: string;
  setGuid?: Function | undefined;
  isWorkAnywhereInWorldChecked?: boolean;
  isWorkAnywhereInUsChecked?: boolean;
  firstJobDetails?: IJob;
  firstJobComDetails?: ICompanies;
  hideJobCount?: boolean;
  showH1Heading?: boolean;
  hideJobCard?: boolean;
  topBanner?: {
    showBanner: boolean;
    bannerTitle?: string;
    bannerImage?: string;
  };
  isRemoteJobsPage?: boolean;
  isRCPortal?: boolean;
  isCompanyComponent?: boolean;
  isCategoryPage?: boolean;
  companyName?: string;
  isLegitimate?: boolean;
  overviewText?: string;
}

const JobListingContainer = (props: IProps) => {
  const {
    localization,
    jobsData,
    savedJobsIdsList = [],
    location,
    isPremiumUser,
    isLoggedIn,
    jobSubCategories,
    categorizedJobs,
    faqData,
    isForLocation,
    isForCategoryWithLocation,
    showWorkAnywhereInUsCheckbox,
    showWorkAnywhereInWorldCheckbox,
    isCategorySubCategory,
    deviceType,
    breadcrumbDropdown,
    countyList,
    isSearchPage,
    topBanner,
    isRemoteJobsPage,
    isCompanyComponent,
    isCategoryJobsPage,
    companyName,
    isCategoryPage,
    isLegitimate,
  } = props;

  const router = useRouter();
  const isRMC = checkIsFJFeatureAllowed();
  const isMobileView: boolean =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';
  const [savedJobsArray, setSavedJobsArray] = useState(savedJobsIdsList);
  const [isOpenDetailJobId, setDetailJobId] = useState<string>(
    Array.isArray(jobsData?.jobs?.results) &&
      jobsData?.jobs?.results.length > 0 &&
      isRMC &&
      isPremiumUser
      ? jobsData.jobs.results[0]?.id
      : ''
  );
  const [jobNumber, setJobNumber] = useState<number>(1);
  const [jobDetails, setJobDetails] = useState<any>(
    props?.firstJobDetails || {}
  );
  const [companyDetails, setCompanyDetails] = useState<any>(
    props?.firstJobComDetails || {}
  );
  const [togglePopOver, setTogglePopOver] = useState<boolean>(false);
  const [showJobDetailPopup, setShowJobDetailPopup] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [unlockBannerSrc, setUnlockBannerSrc] = useState(
    unlockBannerImages[Math.floor(Math.random() * unlockBannerImages.length)]
  );

  const targetElement = useRef<HTMLDivElement | null>(null);
  const jobCardRef = useRef<HTMLDivElement | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const mainWrapperRef = useRef<HTMLDivElement | null>(null);
  const dateButtonRef = useRef<HTMLDivElement | null>(null);
  const relevanceButtonRef = useRef<HTMLDivElement | null>(null);
  const bottomDrawerRef = useRef<any>(null);

  let promoRocketCtaId: number = 5;

  const paginationData = omit(jobsData?.jobs, 'results');

  const sectionsToHide = props.sectionsToHide || [];

  const blob_path = `${process.env.NEXT_PUBLIC_CONTENT_URL}/images`;

  const handleScroll = () => {
    const headerHeight = 170;
    const scrollY = bottomDrawerRef.current
      ? bottomDrawerRef.current.scrollTop
      : null;

    if (scrollY > headerHeight) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    const targetElement = bottomDrawerRef.current;
    targetElement?.addEventListener('scroll', handleScroll);
    return () => {
      targetElement?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getJobIndex = (results: any, jobID: string) => {
    let index = 0;
    if (Array.isArray(results)) {
      index = results.findIndex((obj: JobDetailsResults) => obj.id === jobID);
    }
    return index + 1;
  };

  const handleCloseDrawer = () => {
    setShowJobDetailPopup(false);
    // Push the initial state to history
    window?.history?.back();
  };

  const showDetails = async (
    e: any,
    jobID: string,
    companySlug: string = '',
    disableJobViewed: boolean = false
  ) => {
    if (isMobileView) return;

    let apiPromise = [getJobDetails(jobID)];
    if (companySlug != '' && companySlug != null) {
      apiPromise.push(getCompanyDetails(companySlug, '', true));
    }
    const [jobDetailResp, companyDetailResp] = await Promise.all(apiPromise);
    if (isLoggedIn && jobID) {
      addJobsCountTraits(USER_TRAITS_JOBS_COUNT.VIEWED_JOBS);
      !disableJobViewed && jobViewed(jobID);
    }
    setCompanyDetails(companyDetailResp?.data || {});
    setDetailJobId(jobID);
    setJobDetails(jobDetailResp.data);
    if (isMobileView && isPremiumUser) setShowJobDetailPopup(true);
    const mergerdQueries = {
      ...props.queryParamsByOrder,
      ...router.query,
      joblocations: isForLocation
        ? props.categoryDetails.categoryTitle
        : location,
    };

    // event tracking
    jobSearchResultTrackEvent(
      Action.Viewed,
      getSearchType(),
      ViewType.Job_Post_Details,
      jobDetailResp.data,
      jobsData,
      mergerdQueries,
      null,
      getJobIndex(jobsData.jobs.results, jobID),
      getGuidValue(props.setGuid)
    );
    const imgRandomIndex = Math.floor(
      Math.random() * unlockBannerImages.length
    );
    setUnlockBannerSrc(unlockBannerImages[imgRandomIndex]);
  };

  const hideDetails = () => {
    !isMobileView && setDetailJobId('');
  };

  const openHostedJob = async (e: any, jobid: string) => {
    e.preventDefault();
    if (e.button === 1) {
      // 1 is for mouse center button click
      window.open(`/job-details${jobid}/`, '_blank');
    }
  };

  // get localization data
  const getFaqData = async (slug: string) => {
    const localization = await getLocalizationFunction(slug);
    return localization;
  };

  const setJobIndex = (index: number) => {
    setJobNumber(index);
  };

  const commonProps = {
    savedJobsArray: savedJobsArray,
    setSavedJobsArray: setSavedJobsArray,
    isPremiumUser: isPremiumUser,
    isLoggedIn: isLoggedIn,
    targetElement: targetElement,
  };

  const returnJobDetailsComponent = () => {
    return (
      <JobDetails
        {...commonProps}
        hideDetails={hideDetails}
        jobDetails={jobDetails}
        companyDetails={companyDetails}
        jobId={isOpenDetailJobId}
        savedJob={savedJobsArray?.includes(isOpenDetailJobId)}
        localization={localization}
        jobCardRef={jobCardRef}
        paginationRef={paginationRef}
        jobsData={jobsData}
        deviceType={deviceType}
        queryParams={props.queryParamsByOrder}
        jobNumber={jobNumber}
        guid={props.guid}
        isSearchPage={isSearchPage}
        isPremiumUser={jobDetails?.isFreeJob || isPremiumUser}
        isFreeJob={jobDetails?.isFreeJob}
        mainWrapperRef={mainWrapperRef}
        dateButtonRef={dateButtonRef}
        relevanceButtonRef={relevanceButtonRef}
        unlockBannerSrc={unlockBannerSrc}
        isSticky={isSticky}
        handleCloseDrawer={handleCloseDrawer}
        isLegitimate={isLegitimate}
        isCategoryPage={isCategoryPage}
        isCompanyComponent={isCompanyComponent}
      />
    );
  };

  const showPopover = () => {
    if (!togglePopOver) {
      setTogglePopOver(true);
    }
  };
  const hidePopover = (e: any) => {
    if (togglePopOver && !e.target.classList.contains('fa-info-circle')) {
      setTogglePopOver(false);
    }
  };

  useEffect(() => {
    if (togglePopOver) {
      document.addEventListener('click', hidePopover);
    } else {
      document.removeEventListener('click', hidePopover);
    }
    return () => {
      document.removeEventListener('click', hidePopover);
    };
  });

  useEffect(() => {
    const handlePopState = () => {
      setShowJobDetailPopup(false);
    };

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isMobileView, showJobDetailPopup]);

  // * if user not premieum remove company filter
  let searchFilters = props.jobFilters;
  if (!props.isPremiumUser && Array.isArray(props.jobFilters)) {
    searchFilters = props.jobFilters.filter(
      (filter: any) => filter.name !== 'Company'
    );
  }

  const renderBreadcrumb = () => {
    return (
      <BreadcrumbWrapper>
        <Breadcrumb
          page={props.breadCrumbName}
          dynamicValues={props.breadCrumbNameValue}
          moreDropdownText={props.moreDropdownText}
          skipSubCategoryTitle={props.skipSubCategoryTitle}
          categoryTitle={props.categoryTitle}
          categoryDropdown={props.breadcrumbDropdown}
          showCategoryDropdown={
            Array.isArray(props.moreJobs) && props.moreJobs.length > 0
          }
          isCategoryPage={isCategoryPage}
          isRemoteJobsPage={isRemoteJobsPage}
          isLegitimate={isLegitimate}
        />
      </BreadcrumbWrapper>
    );
  };
  const searchTipsFilter = () => {
    return (
      <>
        <SearchTipsFilter
          jobFilters={searchFilters}
          paginationData={paginationData}
          queryParams={props.queryParamsByOrder}
          location={location}
          searchedJobTitle={props.searchedJobTitle}
          selectedFilters={props.selectedFilters}
          localization={localization}
          isPremiumUser={props.isPremiumUser}
          showJobsCount={props.showJobsCount}
          pageLocation={props.pageLocation}
          showWorkAnywhereInUsCheckbox={showWorkAnywhereInUsCheckbox}
          showWorkAnywhereInWorldCheckbox={showWorkAnywhereInWorldCheckbox}
          isCategorySubCategory={isCategorySubCategory}
          deviceType={deviceType}
          selectedCountry={props.selectedCountry}
          countyList={countyList}
          categoryDetails={props.categoryDetails}
          openSearchOnLocationChange={props.openSearchOnLocationChange}
          showAllDropFilters={props.showAllDropFilters}
          isCategoryJobsPage={isCategoryJobsPage}
          jobsData={jobsData}
          setGuid={props.setGuid}
          guid={props.guid}
          isForLocation={isForLocation}
          isWorkAnywhereInUsChecked={props.isWorkAnywhereInUsChecked}
          isWorkAnywhereInWorldChecked={props.isWorkAnywhereInWorldChecked}
          saveSeachButtonState={props.saveSeachButtonState}
          mainWrapperRef={mainWrapperRef}
          hideDetails={hideDetails}
          dateButtonRef={dateButtonRef}
          relevanceButtonRef={relevanceButtonRef}
          hideJobCount={props.hideJobCount}
          isSearchPage={isSearchPage}
          isRemoteJobsPage={isRemoteJobsPage}
          isRCPortal={isRCPortal}
          isOpenDetailJobId={isOpenDetailJobId}
          isLoggedIn={isLoggedIn}
          isCategoryPage={isCategoryPage}
          isLegitimate={isLegitimate}
          overviewText={props.overviewText}
        />
      </>
    );
  };
  const current_job_heading_new =
    localization.current_job_heading_new &&
    localization.current_job_heading_new?.split('{0}')[0];
  const CategoriesDetails = () => {
    return (
      <CategoryDetails
        categoryDetails={props.categoryDetails}
        getFaqData={getFaqData}
        categorySlug={props.queryParamsByOrder.category}
        subCategories={jobSubCategories}
        localization={{
          categories_text: localization.categories_text,
        }}
        faqData={
          faqData && props.categoryDetails?.slug
            ? faqData[props.categoryDetails.slug]
            : ''
        }
        categorySuccessStories={props.categorySuccessStories}
        isPremiumUser={props.isPremiumUser}
        mobileView={isMobileView}
        isForLocation={isForLocation}
        isForCategoryWithLocation={isForCategoryWithLocation}
        pageLocation={props.pageLocation}
        breadcrumbDropdown={breadcrumbDropdown}
        tabsToHide={props.tabsToHide}
        isLoggedIn={isLoggedIn}
        isRCPortal={isRCPortal}
      />
    );
  };
  const isNotRemotePages =
    (props.breadCrumbName && isRemoteJobsPage) ||
    (isRCPortal && renderBreadcrumb());
  const isRemotePage =
    props.breadCrumbName &&
    isRemoteJobsPage &&
    isRCPortal &&
    renderBreadcrumb();
  const VIEW_TEXT = {
    more: 'View More',
    less: 'View Less',
  };
  const [viewMore, setViewMore] = useState(VIEW_TEXT.more);
  const [descriptionShort, setDescriptionShort] = useState(
    localization.remoteJobDescription.slice(0, 180) + ' ...'
  );
  const handleViewMore = () => {
    if (viewMore == VIEW_TEXT.less) {
      setViewMore(VIEW_TEXT.more);
      setDescriptionShort(
        localization.remoteJobDescription.slice(0, 180) + ' ...'
      );
    }
    if (viewMore == VIEW_TEXT.more) {
      setViewMore(VIEW_TEXT.less);
      setDescriptionShort(localization.remoteJobDescription);
    }
  };
  return (
    <Wrapper isSearchPage={props.isSearchPage} isLegitimate={isLegitimate}>
      {topBanner && topBanner.showBanner && (
        <BannerSection
          bannerTile={topBanner.bannerTitle || 'Remote Jobs'}
          bannerImage={
            topBanner.bannerImage ||
            (isMobileView ? 'runner_expt_mob.webp' : 'runner_expt.webp')
          }
          isPremiumUser={isPremiumUser}
          isMobileView={isMobileView}
        />
      )}

      {isRCPortal && isCategoryPage && (
        <>
          <BreadcrumbOuterWrapper>
            <StyledContainerMd isPrimaryContainer={isRCPortal}>
              {isMobileView ? (
                <>{renderBreadcrumb()}</>
              ) : (
                <>{renderBreadcrumb()} </>
              )}
            </StyledContainerMd>
          </BreadcrumbOuterWrapper>
        </>
      )}
      {/* search Filter */}
      {isRCPortal &&
        sectionsToHide?.includes(allowedSectionsToHide.CATEGORY_TABS) && (
          <BreadcrumbOuterWrapper>
            <StyledContainerMd isPrimaryContainer={isRCPortal}>
              {isMobileView ? (
                <>
                  {isRemoteJobsPage || isCompanyComponent
                    ? isRemotePage
                    : isNotRemotePages}
                </>
              ) : (
                <>
                  {isRemoteJobsPage || isCompanyComponent
                    ? isRemotePage
                    : isNotRemotePages}
                </>
              )}
            </StyledContainerMd>
          </BreadcrumbOuterWrapper>
        )}

      {!sectionsToHide?.includes(allowedSectionsToHide.CATEGORY_TABS) &&
        isRCPortal && (
          <StyledContainerMd isPrimaryContainer={isRCPortal}>
            {/* {CategoriesDetails()} */}
          </StyledContainerMd>
        )}
      {/* {(localization.heading || localization.sub_heading) && showH1Heading && (
        <HeaderSection
          heading={localization.heading.replace(`{0}`, props.pageName || '')}
          description={
            localization.sub_heading ? `<p>${localization.sub_heading}</p>` : ''
          }
        />
      )} */}
      {!sectionsToHide?.includes(allowedSectionsToHide.SEARCH_FILTER) &&
        isRCPortal &&
        searchTipsFilter()}

      <StyledContainerMd isPrimaryContainer={isRCPortal}>
        {isMobileView ? (
          <></>
        ) : (
          <>
            {(props.breadCrumbName && isRemoteJobsPage) ||
              (!isRCPortal && renderBreadcrumb())}
          </>
        )}
        {isOpenDetailJobId !== '' && isRCPortal && isCategoryPage && (
          <>
            <HeaderSection
              isCategoryPage={isCategoryPage}
              heading={`Remote ${props?.categoryDetails?.name} Jobs - Work From Home`}
              description={
                props?.categoryDetails?.categoryOverview
                  ? `<p>${props?.categoryDetails?.categoryOverview}</p>`
                  : ''
              }
              isMobileView={isMobileView}
            />
          </>
        )}
        {isOpenDetailJobId !== '' && isRCPortal && props.overviewText && (
          <HeaderSection
            isCategoryPage={true}
            heading={topBanner?.bannerTitle?.replace('Online ', '') || ''}
            description={`<p>${props.overviewText}</p>`}
            isMobileView={isMobileView}
          />
        )}
        <FlexLayout
          gridCol={
            isOpenDetailJobId !== ''
              ? isRCPortal
                ? '50% 50%'
                : '50% 50%'
              : isRemoteJobsPage
              ? '1fr 30rem'
              : isCategoryPage
              ? '1fr 30rem'
              : '75% 25%'
          }
          leftSectionrole="region"
          rightSectionrole="article"
          deviceType={deviceType}
          isRMCPage={!isRMC}
          isRCPortal={isCompanyComponent}
        >
          <>
            {isMobileView ? (
              <></>
            ) : (
              props.breadCrumbName &&
              !isRemoteJobsPage &&
              !isRCPortal &&
              renderBreadcrumb()
            )}
            {isOpenDetailJobId == '' && isRCPortal && isCategoryPage && (
              <>
                <HeaderSection
                  isCategoryPage={isCategoryPage}
                  heading={`Remote ${props?.categoryDetails?.name} Jobs - Work From Home`}
                  description={
                    props?.categoryDetails?.categoryOverview
                      ? `<p>${props?.categoryDetails?.categoryOverview}</p>`
                      : ''
                  }
                  isMobileView={isMobileView}
                />
              </>
            )}
            {isOpenDetailJobId == '' && isRCPortal && props.overviewText && (
              <HeaderSection
                isCategoryPage={true}
                heading={topBanner?.bannerTitle?.replace('Online ', '') || ''}
                description={`<p>${props.overviewText}</p>`}
                isMobileView={isMobileView}
              />
            )}
            {(localization.heading || localization.sub_heading) &&
              !isRCPortal && (
                <HeaderSection
                  heading={localization.heading.replace(
                    `{0}`,
                    props.pageName || ''
                  )}
                  description={
                    localization.sub_heading
                      ? `<p>${localization.sub_heading}</p>`
                      : ''
                  }
                />
              )}
            {!sectionsToHide?.includes(
              allowedSectionsToHide.SURPRISING_JOBS_FILTER
            ) &&
              localization.jobs_description && (
                <p>{localization.jobs_description}</p>
              )}
            {!sectionsToHide?.includes(allowedSectionsToHide.CATEGORY_TABS) &&
              !isRCPortal &&
              CategoriesDetails()}

            {/* {!sectionsToHide?.includes(allowedSectionsToHide.SEARCH_FILTER) &&
              isRCPortal &&
              isRemoteJobsPage &&
              searchTipsFilter()} */}
            {isRCPortal && isRemoteJobsPage && !isLoggedIn && (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: isMobileView
                      ? descriptionShort
                      : localization.remoteJobDescription,
                  }}
                />

                {isMobileView && (
                  <ViewMoreBtn
                    href="#"
                    onClick={handleViewMore}
                    isTextMore={viewMore == VIEW_TEXT.more}
                  >
                    {viewMore}
                  </ViewMoreBtn>
                )}
              </>
            )}
            {!sectionsToHide?.includes(allowedSectionsToHide.SEARCH_FILTER) ||
              (!isRCPortal && (
                <SearchTipsFilter
                  jobFilters={searchFilters}
                  paginationData={paginationData}
                  queryParams={props.queryParamsByOrder}
                  location={location}
                  searchedJobTitle={props.searchedJobTitle}
                  selectedFilters={props.selectedFilters}
                  localization={localization}
                  isPremiumUser={props.isPremiumUser}
                  showJobsCount={props.showJobsCount}
                  pageLocation={props.pageLocation}
                  showWorkAnywhereInUsCheckbox={showWorkAnywhereInUsCheckbox}
                  showWorkAnywhereInWorldCheckbox={
                    showWorkAnywhereInWorldCheckbox
                  }
                  isCategorySubCategory={isCategorySubCategory}
                  deviceType={deviceType}
                  selectedCountry={props.selectedCountry}
                  countyList={countyList}
                  categoryDetails={props.categoryDetails}
                  openSearchOnLocationChange={props.openSearchOnLocationChange}
                  showAllDropFilters={props.showAllDropFilters}
                  jobsData={jobsData}
                  setGuid={props.setGuid}
                  guid={props.guid}
                  isForLocation={isForLocation}
                  isWorkAnywhereInUsChecked={props.isWorkAnywhereInUsChecked}
                  isWorkAnywhereInWorldChecked={
                    props.isWorkAnywhereInWorldChecked
                  }
                  saveSeachButtonState={props.saveSeachButtonState}
                  mainWrapperRef={mainWrapperRef}
                  hideDetails={hideDetails}
                  dateButtonRef={dateButtonRef}
                  relevanceButtonRef={relevanceButtonRef}
                  hideJobCount={props.hideJobCount}
                  isSearchPage={isSearchPage}
                  isRemoteJobsPage={isRemoteJobsPage}
                />
              ))}

            {isCompanyComponent
              ? null
              : sectionsToHide?.includes(
                  allowedSectionsToHide.SEARCH_FILTER
                ) && (
                  <PopUpwrapper>
                    {localization.job_icon_guide_heading}
                    <InfoPopOver
                      className="featuredjob-popover"
                      id={'featuredjob-head-info'}
                      onClick={showPopover}
                      toggleStatus={togglePopOver}
                      mobTransform={'-43%, 0%'}
                    />
                  </PopUpwrapper>
                )}
            {isCompanyComponent && (
              <>
                <CompaniesHeading className="companies-heading">{`${current_job_heading_new} ${companyName}`}</CompaniesHeading>
                <CompaniesSubHeading>
                  {localization.current_jobs}
                </CompaniesSubHeading>
              </>
            )}

            {isRCPortal
              ? localization.jobs_heading && (
                  <h2>{localization.jobs_heading}</h2>
                )
              : localization.jobs_heading && (
                  <h2>{localization.jobs_heading}</h2>
                )}

            {isMobileView && jobsData?.jobs?.totalCount >= 0 && (
              <JobCountWrapper id="job-count-test-mobile">
                {`${jobsData?.jobs?.totalCount?.toLocaleString()} job search results`}
              </JobCountWrapper>
            )}
            {!props.hideJobCard && (
              <>
                {jobsData &&
                jobsData.jobs &&
                jobsData.jobs?.results?.length > 0 ? (
                  <JobCardWrapper ref={jobCardRef} id="job-table-wrapper">
                    {jobsData.jobs.results.map((jobs: any, idx: number) => {
                      let showPromoRocketCta: boolean = false;

                      if (idx + 1 === promoRocketCtaId) {
                        showPromoRocketCta = true;
                        promoRocketCtaId = promoRocketCtaId + 20;
                      }

                      return (
                        <SubJobCardWrapper key={jobs.id}>
                          <JobCard
                            {...commonProps}
                            jobsData={jobs}
                            key={jobs.id}
                            openHostedJob={openHostedJob}
                            showDetails={showDetails}
                            activeDetailId={isOpenDetailJobId}
                            isActive={isOpenDetailJobId == jobs.id}
                            savedJob={savedJobsArray?.includes(jobs.id)}
                            showNotes={props.isPremiumUser}
                            jobDetails={jobDetails}
                            deviceType={deviceType}
                            setJobIndex={setJobIndex}
                            customAttribute={idx + 1}
                            jobNumber={jobNumber}
                            jobsList={jobsData}
                            guid={props.guid}
                            isFreeJob={jobs?.isFreeJob}
                          />
                          {isMobileView &&
                            showPromoRocketCta &&
                            !props.isLoggedIn && (
                              <PromoRocketCta>
                                <RocketImageWrap>
                                  <RocketImage
                                    src={`${blob_path}/img-rocket-alt.svg`}
                                    alt="img rocket"
                                  />
                                </RocketImageWrap>
                                <PromoContent>
                                  <PromoHead>
                                    {localization.promo_cta.promo_head}
                                  </PromoHead>
                                  <PromoHeadSub>
                                    {localization.promo_cta.promo_head_sub}
                                  </PromoHeadSub>
                                  <Button
                                    url="/signup/"
                                    text={localization.promo_cta.promo_btn}
                                    buttonType="tertiary"
                                    buttonSize="lg"
                                    ButtonClass="promo-join-btn"
                                    underlineOnHover
                                  ></Button>
                                </PromoContent>
                              </PromoRocketCta>
                            )}
                        </SubJobCardWrapper>
                      );
                    })}
                  </JobCardWrapper>
                ) : (
                  <NoResultWrap>
                    <EmptySearchResult isSearchPage={false} />
                  </NoResultWrap>
                )}
                {props.isPremiumUser && (
                  <BtnWrap>
                    <Button
                      buttonType="primary"
                      icon="fa-regular fa-chevron-left"
                      text={'Go To My Dashboard'}
                      url={isRMC ? '/members' : '/dashboard'}
                      buttonSize="sm"
                    />
                  </BtnWrap>
                )}
              </>
            )}

            {!props.hideJobCard && jobsData?.jobs?.totalPages > 0 && (
              <PaginationComponent
                pageCount={jobsData?.jobs?.totalPages}
                paginationRef={paginationRef}
                hideDetails={hideDetails}
                isMobileView={isMobileView}
              />
            )}

            {/* As per FJ-307 Removed */}
            {props.isPremiumUser &&
              !isRemoteJobsPage &&
              !isCompanyComponent &&
              !isRCPortal && (
                <BtnWrap>
                  <Button
                    buttonType="primary"
                    icon="fa-regular fa-chevron-left"
                    text={'Go To My Dashboard'}
                    url={isRMC ? '/members' : '/dashboard'}
                    buttonSize="sm"
                  />
                </BtnWrap>
              )}
            {isRemoteJobsPage && (
              <FreeRemoteJobsList
                JobsList={categorizedJobs}
                isPremium={isPremiumUser}
                isMobileView={isMobileView}
              />
            )}
          </>

          <>
            {isOpenDetailJobId !== '' ? (
              returnJobDetailsComponent()
            ) : (
              <JobsAction
                isRCPortal={isRCPortal}
                isRemoteJobsPage={isRemoteJobsPage}
                isCompanyComponent={isCompanyComponent}
                isLoggedIn={isLoggedIn}
              />
            )}
          </>
        </FlexLayout>
      </StyledContainerMd>
    </Wrapper>
  );
};

export default JobListingContainer;

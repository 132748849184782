import {
  Categories,
  Category,
  IDeviceType,
  ILocalJobCount,
  ILoggedInUser,
  ISavedSearchItem,
  JobDetailsResults,
  LocationLookupItem,
} from '@components/shared.types';
import {
  checkCookieHeaders,
  deviceTypeByUserAgent,
  formatDate,
  getLoginStatusUsingCookie,
  identifyAndRemoveVulnerableText,
  parseJSON,
} from '@license-admin/boldfjcomponents';
import { getApiCallDuration } from 'API/ApiEndPoint';
import {
  getCountryListService,
  getEcomFlowData,
  getJobCategories,
  getPremiumStatus,
  logErrorServer,
  updateUserPreferences,
} from 'API/ApiHelpers';
import {
  getCompaniesHiringCount,
  getLocalizationAndConfig,
  getNews,
  getSavedJobsId,
  getSuccessStories,
  saveJobApi,
} from 'API/jobs';
import {
  MODAL_CLOSE_POP_UP,
  POP_UP_EVENT,
  USER_TRAITS_JOBS_COUNT,
} from 'helper/eventTrackingUtils/constant';
import { ServerResponse } from 'http';
import {
  API_NAMES,
  ApiBaseUrl,
  ApiBaseUrlServer,
  DISALLOW_MULTISELECT,
  FIELD_TYPE_CD,
  LOCAL_STORAGE_CONSTS,
  LOCATION_CHECK_BOX_VALUE,
  LocalStorageKeys,
  SECTION_TYPE_CD,
  US_GLOBAL_LOCATION,
  _CONSTANTS,
  baseConfig,
  commonRegex,
  filterDropOrder,
  locationQueryMapping,
  savedJobsField,
  selectedFiltersOrder,
} from './constant';
import { US_CITIES, US_STATES } from './constants/locations';
import {
  addJobsCountTraits,
  closePopUpEvent,
  genericClickEvent,
} from './event-tracker';
import { getFilterQueryString, mapQueryParams } from './filterHelper';

import rmcCategories from '@components/common/rmcCategories.json';
import { ebEndpoints } from 'enpoints/eb';
import { ejEndpoints } from 'enpoints/ej';

export type VulnerableParams = {
  removeVulnerableText: boolean;
  isVulnerableTextPresent: boolean;
};

export const getTheme = (
  props: any,
  styleElem: string,
  key: string,
  child: string
) => {
  return props.theme.Component[styleElem][key][child];
};

export const isAuthUser = (): LoggedInUser => {
  const defaultResp: LoggedInUser = {
    IsUserLoggedIn: false,
    User: {
      AccDisplayName: '',
      CreatedOn: '',
      Role: 0,
      UserId: '',
    },
  };

  if (typeof window === 'object' && window.document.cookie) {
    const userCookies =
      window.document.cookie
        .match('(^|;)\\s*' + 'UserStatus' + '\\s*=\\s*([^;]+)')
        ?.pop() || '';
    const userStatus = userCookies
      ? parseJSON(userCookies, logErrorServer, true, 'isAuthUser', 'FJ_JOBS')
      : defaultResp;
    return userStatus as LoggedInUser; // Add type assertion to ensure the return type is always LoggedInUser
  } else {
    return defaultResp;
  }
};

type LoggedInUser = {
  IsUserLoggedIn: boolean;
  User: {
    AccDisplayName: string;
    CreatedOn: string;
    Role: number;
    UserId: string;
  };
};

type InitialFiltersType = {
  name: string;
  categoryWithCount: { [key: string]: number };
};

export const hideFilters = (data: Array<InitialFiltersType>) => {
  let visibleFiltersList: Array<InitialFiltersType> = data.filter(
    (item: InitialFiltersType) =>
      !baseConfig.hideFilters?.includes(
        item.name.toLowerCase().replace(' ', '')
      )
  );
  return visibleFiltersList;
};

export const saveBookMarkedJobs = () => {
  const savedJobsString = localStorageGetItem(savedJobsField);
  const savedJobIds: string[] = savedJobsString
    ? parseJSON(
        savedJobsString,
        logErrorServer,
        true,
        'saveBookMarkedJobs',
        'FJ_JOBS'
      )
    : [];
  if (savedJobIds.length < 1) {
    return;
  }
  if (savedJobIds) {
    addJobsCountTraits(USER_TRAITS_JOBS_COUNT.SAVED_JOBS, savedJobIds.length);
  }
  const savePromises = savedJobIds.map(async (id: string) => {
    return saveJobApi(id, true);
  });
  Promise.all(savePromises)
    .then(() => {
      localStorageRemoveItem(savedJobsField);
      return true;
    })
    .catch((error) => {
      console.error('Error saving jobs:', error);
      return false;
    });
};

export const readCookie = (key: string, ssrCookie?: string) => {
  let cookies = '';
  if (ssrCookie) {
    cookies =
      ssrCookie.match('(^|;)\\s*' + `${key}` + '\\s*=\\s*([^;]+)')?.pop() || '';
  } else if (typeof window === 'object' && window.document.cookie) {
    cookies =
      window.document.cookie
        .match('(^|;)\\s*' + `${key}` + '\\s*=\\s*([^;]+)')
        ?.pop() || '';
  }
  return cookies;
};

export const checkAuthUser = (response: any, cookies: any) => {
  if (
    Object.keys(cookies).length == 0 ||
    !cookies.UserStatus ||
    !parseJSON(
      cookies.UserStatus,
      logErrorServer,
      true,
      'checkAuthUser function',
      'FJ_JOBS'
    )?.IsUserLoggedIn
  ) {
    response.writeHead(307, { Location: '/login' });
    response.end();
  }
};

export const redirectToPage = (
  response: any,
  condition: boolean,
  path: string
) => {
  if (condition) {
    response.writeHead(307, { Location: basePathRewrite(path) });
    response.end();
  }
};

type SortArrType = {
  [key: string]: string;
  Remote: string;
  'Job Type': string;
  Schedule: string;
  'Career Level': string;
  Education: string;
  Travel: string;
  Category: string;
  Accolades: string;
  Title: string;
};

export const sanitizeEmptyFilters = (jobsFilters: any) => {
  if (Array.isArray(jobsFilters))
    return jobsFilters.filter(
      (filters: any) =>
        Object.keys(filters.categoryWithCount).length ||
        (filters?.category && Object.keys(filters.category).length)
    );
  return jobsFilters;
};

export const arrangeFiltersByOrder = (
  data: any,
  useReplace?: boolean,
  removeItemsFromFilter?: any,
  deviceType?: IDeviceType
) => {
  const sortArr: SortArrType = {
    Remote: 'remoteoptions',
    'Job Type': 'jobtypes',
    Schedule: 'jobschedules',
    'Career Level': 'careerlevel',
    Education: 'educations',
    Travel: 'travels',
    Category: 'categories',
    Accolades: 'accolades',
    Company: 'companies',
    Title: 'jobtitles',
  };
  if (useReplace) {
    // add slug to each object which is used for mapping api accepting keys and filter the items not available in sortArr
    const newData = data
      .map((obj: any) => {
        return {
          ...obj,
          slug: sortArr[obj.name],
        };
      })
      .filter((obj: any) => sortArr[obj.name]);

    // sort the array of objects based on the sortArr
    const sortedArr = newData?.sort((a: any, b: any) => {
      return (
        Object.keys(sortArr).indexOf(a.name) -
        Object.keys(sortArr).indexOf(b.name)
      );
    });
    if (deviceType !== 'mobile') {
      return sanitizeFilters(removeItemsFromFilter, sortedArr);
    }
    return sortedArr;
  } else {
    let dataByOrder: any = {};
    Object.values(sortArr).map((order: string) => {
      Object.keys(data).map((param: string) => {
        if (param.toLowerCase() === order) {
          dataByOrder[param] = data[param];
        }
      });
    });
    return dataByOrder;
  }
};

export const sanitizeFilters = (
  removeItemsFromFilter: any,
  filterData: any
) => {
  if (Object.keys(removeItemsFromFilter).length > 0) {
    filterData.map((item: any) => {
      if (
        DISALLOW_MULTISELECT?.includes(item.slug) &&
        removeItemsFromFilter[item.slug] &&
        removeItemsFromFilter[item.slug].length > 0
      ) {
        /**
         * Currently We have only one for Career Level Rest case of jobTitle, company is handled in the filter itself from API
         * Career Level can be selected multiple from Advance Search
         */
        const filteredData: any = {};
        if (Array.isArray(removeItemsFromFilter[item.slug])) {
          removeItemsFromFilter[item.slug].forEach((key: string) => {
            filteredData[key] = item.categoryWithCount[key];
          });
        } else {
          let keyItem =
            Object.keys(item.categoryWithCount).find(
              (key: any) =>
                key.toLowerCase() ===
                removeItemsFromFilter[item.slug].toLowerCase()
            ) || '';
          filteredData[keyItem] = item.categoryWithCount[keyItem];
        }

        item.categoryWithCount = filteredData;
        return item;
      }
      return item;
    });
  }
  return sanitizeEmptyFilters(filterData);
};

export function getClientSettingValue(settingKey: string): any {
  const clientSettings = parseJSON(
    localStorageGetItem(_CONSTANTS.COMMON) || '{}',
    logErrorServer,
    true,
    'getClientSettingValue function',
    'FJ_JOBS'
  ).clientSettings;
  const setting = clientSettings?.find((setting: any) =>
    Object.prototype.hasOwnProperty.call(setting, settingKey)
  );
  return setting ? setting[settingKey] : null;
}

export function setClientSettings(action: { key: string; value: any }) {
  const localStorageKey = _CONSTANTS.COMMON;
  let state = parseJSON(
    localStorageGetItem(localStorageKey) || '{}',
    logErrorServer,
    true,
    'setClientSettings function',
    'FJ_JOBS'
  );
  const { key, value } = action;

  const settingIndex = state.clientSettings
    ? state.clientSettings.findIndex((setting: any) =>
        Object.prototype.hasOwnProperty.call(setting, key)
      )
    : -1;

  if (settingIndex === -1) {
    state = {
      ...state,
      clientSettings: [...(state?.clientSettings || []), { [key]: value }],
    };
  } else {
    state = {
      ...state,
      clientSettings: state.clientSettings.map((setting: any, index: number) =>
        index === settingIndex
          ? Object.assign({}, setting, { [key]: value })
          : setting
      ),
    };
  }
  const updatedState = { ...state, clientSettings: state.clientSettings };
  localStorageSetItem(localStorageKey, JSON.stringify(updatedState));
}

export const basePathRewrite = (path: string = '') => {
  const environment =
    process.env.NEXT_PUBLIC_ENV === 'prod'
      ? ''
      : process.env.NEXT_PUBLIC_ENV + '.';

  let urlPath = path;
  if (path?.includes('http')) {
    urlPath = path.replace('http://', `https://`);
  } else {
    urlPath = `https://${environment}${process.env.NEXT_PUBLIC_SITE_DOMAIN}${path}`;
  }

  if (process.env.NEXT_PUBLIC_ENV === 'prod' && !urlPath?.includes('www')) {
    return urlPath.replace('https://', `https://${environment}`);
  }
  return urlPath;
};

export const getAbsoluteImgPath = (path: string) => {
  if (typeof path === 'string') {
    return path.startsWith('https://')
      ? path
      : `${process.env.NEXT_PUBLIC_CONTENT_URL}${path}`;
  }
  return '';
};

export const getShortDesc = (summ: string) => {
  const desc = summ.replace(commonRegex.htmlTags, '').substring(0, 220) + '...';
  return desc;
};

export const displayThousandsOnly = (jobsCount: number) => {
  if (jobsCount < 1000) {
    return 1;
  } else {
    return Math.floor(jobsCount / 1000);
  }
};

export const getItemCountForSplitting = (
  itemCount: number,
  colCount: number
): Array<number> => {
  const splits: Array<number> = [];
  if (itemCount < colCount) splits.push(-1);
  else if (itemCount % colCount == 0) {
    for (let i = 0; i < colCount; i++) splits.push(itemCount / colCount);
  } else {
    let fullColCount = itemCount % colCount;
    let partialColCount = Math.floor(itemCount / colCount);
    for (let i = 0; i < colCount; i++) {
      if (i < fullColCount) splits.push(partialColCount + 1);
      else splits.push(partialColCount);
    }
  }
  return splits;
};

type ReponsePropsSSR = {
  fullUrl: string;
  localization: any;
  config: any;
  isPremiumUser: boolean;
  isLoggedIn: boolean;
  deviceType: IDeviceType;
  cookieHeader: string;
  cookies: any;
  cookieValue: any;
  selectedFilters: { [key: string]: Array<string> };
  themeType: string;
};

export const commonPropsForSSR = async (
  req: any,
  localizationKey: string = '*',
  preSelectedFilter: any = {}
): Promise<ReponsePropsSSR> => {
  let { cookieHeader, cookies } = checkCookieHeaders(req.headers.cookie);
  const isLoggedIn = getLoginStatusUsingCookie(cookies);
  const cookieValue =
    (cookies.UserStatus &&
      parseJSON(
        cookies.UserStatus,
        logErrorServer,
        true,
        'commonPropsForSSR',
        'FJ_JOBS',
        cookieHeader
      )) ||
    '';

  const deviceType: IDeviceType = deviceTypeByUserAgent(
    req.headers['user-agent'] || ''
  );

  let UserId = '';
  if (cookieValue) {
    UserId = cookieValue.User.UserId;
  }

  const selectedFilters = mapQueryParams(preSelectedFilter);

  const promiseList = [
    getLocalizationAndConfig(localizationKey),
    getPremiumStatus(UserId, isLoggedIn, cookieHeader),
  ];

  const [localizationAndConfig, isPremiumUser] = await Promise.all<any>(
    promiseList
  );

  const themeType = readCookie('theme', cookieHeader) || 'graphite';

  return {
    fullUrl: getfullUrl(req),
    localization: localizationAndConfig[0],
    config: localizationAndConfig[1],
    isPremiumUser: isPremiumUser,
    isLoggedIn: isLoggedIn,
    deviceType: deviceType,
    cookieHeader: cookieHeader ?? null,
    cookies: cookies,
    cookieValue: cookieValue,
    selectedFilters: selectedFilters,
    themeType: themeType,
  };
};

export const makeApiCalls = async (
  input: string[],
  cookieHeader: string = '',
  isPremiumUser: boolean = false
): Promise<any[]> => {
  try {
    const apiCalls = input.map(async (code) => {
      switch (code) {
        case API_NAMES.COUNTRY_LIST_EB:
          return getCountryListService();
        case API_NAMES.NEWS_WIDGET:
          return checkIsFJFeatureAllowed() ? getNews() : {};
        case API_NAMES.SUCCESS_STORY_WIDGET:
          return checkIsFJFeatureAllowed()
            ? getSuccessStories(cookieHeader)
            : {};
        case API_NAMES.HIRING_COUNT:
          return getCompaniesHiringCount();
        case API_NAMES.SAVE_JOB_ID:
          return getSavedJobsId(isPremiumUser, cookieHeader);
        case API_NAMES.JOB_CATEGORIES:
          return getJobCategories(cookieHeader);
        default:
          throw new Error(`Invalid input code: ${code}`);
      }
    });

    const results = await Promise.all(apiCalls);
    return results;
  } catch (error) {
    console.error('Error calling multiple APIs:', error);
    return [];
  }
};

export const commonPropsForPartnerPages = async (
  req: any,
  partnerLocalizationNeeded: boolean,
  partnerImgPath: string
) => {
  let { cookieHeader, cookies } = checkCookieHeaders(req.headers.cookie);
  const cookieValue =
    (cookies.UserStatus &&
      parseJSON(
        cookies.UserStatus as ILoggedInUser,
        logErrorServer,
        true,
        'commonPropsForPartnerPages function',
        'FJ_JOBS'
      )) ||
    '';
  const deviceType: IDeviceType = deviceTypeByUserAgent(
    req.headers['user-agent'] || ''
  );

  let UserId = '';
  if (cookieValue) {
    UserId = cookieValue.User.UserId;
  }

  const commonResponse = {
    deviceType: deviceType,
    isPartnerPage: true,
    partnerImgPath: `${process.env.NEXT_PUBLIC_CONTENT_URL}${partnerImgPath}`,
  };
  const isLoggedIn = getLoginStatusUsingCookie(cookies);
  if (partnerLocalizationNeeded) {
    const [[localization, config], [partnerLocalization], isPremiumUser] =
      await Promise.all([
        getLocalizationAndConfig('partner_login'),
        getLocalizationAndConfig('partner_static'),
        getPremiumStatus(UserId, isLoggedIn, cookieHeader),
      ]);

    return {
      localization: localization,
      partnerLocalization: partnerLocalization,
      config: config,
      isPremiumUser: isPremiumUser,
      ...commonResponse,
    };
  } else {
    const [[localization, config], isPremiumUser] = await Promise.all([
      getLocalizationAndConfig('partner_login'),
      getPremiumStatus(UserId, isLoggedIn, cookieHeader),
    ]);

    return {
      localization: localization,
      config: config,
      isPremiumUser: isPremiumUser,
      ...commonResponse,
    };
  }
};

export const removeEmptySaveSearch = (data: Array<ISavedSearchItem>) => {
  if (
    !data ||
    !Array.isArray(data) ||
    (Array.isArray(data) && data.length === 0)
  )
    return [];
  const savedSearches =
    data[0].name === null || data[0].search === null ? data.slice(1) : data;
  return savedSearches;
};

export const getFilterQueryStringForLocation = (query: any = {}) => {
  let queryString: string = getFilterQueryString(query);
  if (
    query.category &&
    query.locationRoot &&
    query.category.toLowerCase() === 'usa'
  ) {
    queryString = queryString
      .replace('category=', 'Loc.Country=')
      .replace('&locationRoot=', '&Loc.State=');
    if (query.locationChild)
      queryString = queryString.replace('&locationChild=', '&Loc.City=');
  } else if (
    query.category &&
    query.locationRoot &&
    query.category.toLowerCase() === 'world'
  ) {
    queryString = queryString
      .replace('&locationRoot=', '&Loc.Country=')
      .replace('category=world&', '');

    if (query.locationChild)
      queryString = queryString.replace('&locationChild=', '&Loc.State=');
    // queryString += `Loc.State=${query.locationChild}`;
  }
  return queryString;
};

export const sanitizeStringJobSchedule = (queryString: string) => {
  const removeDuplicateQueryString = (queryString: string, param: string) => {
    const regex = new RegExp(`&?${param}=`, 'g');
    const occurrenceCount = (queryString.match(regex) || []).length;

    if (occurrenceCount > 1) {
      queryString = queryString.replace(regex, '');
    }

    return queryString;
  };

  queryString = removeDuplicateQueryString(
    queryString,
    'jobschedules=Full-Time'
  );
  queryString = removeDuplicateQueryString(
    queryString,
    'jobschedules=Part-Time'
  );
  queryString = removeDuplicateQueryString(queryString, 'jobtypes=Freelance');

  return queryString;
};

export const getCompleteWordSubstring = (
  text: string | null,
  maxLength: number
) => {
  if (!text || text.length <= maxLength) return text;
  let trimmedString = text.substring(0, maxLength);
  trimmedString = trimmedString.substring(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );

  return trimmedString;
};

export const getSlug = (str: string) => {
  return str.replace(/\. /g, '-').replace(/ /g, '-').toLowerCase();
};

export const getCategoryTitleFromConst = (query: any = {}) => {
  if (
    query.category &&
    query.locationRoot &&
    query.category.toLowerCase() === 'usa' &&
    query.locationChild
  ) {
    const matchedCity = US_CITIES.find(
      (c) =>
        getSlug(c.city) === getSlug(query.locationChild) &&
        getSlug(c.code) === getSlug(query.locationRoot)
    );
    if (matchedCity) return `${matchedCity.city}, ${matchedCity.code}`;
    return null;
  }
};

export const modifyLocationLookupData = (data: LocationLookupItem) => {
  let key = `${data.name || data.cityName}, ${
    data.stateName || data.countryName
  }`;
  key = key.split(', ')[1] === '@@@' ? key.split(', ')[0] : key;
  let value = encodeURIComponent(
    `${encodeURIComponent(data.name || '')}, ${encodeURIComponent(
      data.cityName || ''
    )}, ${encodeURIComponent(data.stateName || '')}, ${encodeURIComponent(
      data.countryName || ''
    )}`
  );
  let latlong = `${data.latitude}, ${data.longitude}`;
  return { key, value, latlong };
};

export const getNextEntriesOfObject = (
  object: Record<string, string>,
  key: string,
  count: number,
  suffix: string = '',
  lastEntry?: Category
) => {
  const keys = Object.keys(object);
  const index = keys.indexOf(key);

  if (index === -1) {
    return 'Invalid key';
  }

  const nextKeys = keys.slice(index + 1, index + count + 1);

  if (nextKeys.length < count) {
    const remainingKeys = keys.slice(0, count - nextKeys.length);
    nextKeys.push(...remainingKeys);
  }

  const nextJobs: Category[] = [];

  nextKeys.forEach((k) => {
    nextJobs.push({
      slug: k,
      name: object[k] + suffix,
    });
  });

  lastEntry && nextJobs.push(lastEntry);

  return nextJobs;
};

export const decodeCompleteEncodedString = (encodedString: string) => {
  let decodedString = encodedString;
  try {
    decodedString = decodeURIComponent(encodedString);
    while (decodedString !== encodedString) {
      encodedString = decodedString;
      decodedString = decodeURIComponent(encodedString);
    }
    return decodedString;
  } catch (err) {
    // in case of decoding %25(encoding of %) again decodeURIComponent throws error
    return decodedString;
  }
};

// Function to modify the selected location
export const modifySelectedLocation = (
  selectedLocation: string,
  countyList?: any
) => {
  // Initialize location object with default values
  let locationObj = {
    name: '',
    cityName: '',
    stateName: '',
    countryName: '',
  };
  let isInternationalLocation = false;
  let locationNameAndState = '';
  // If no selected location, return empty location object
  if (!selectedLocation) {
    return { locationObj, isInternationalLocation, locationNameAndState };
  }

  const encodedString = selectedLocation;
  // Decode the selected location string
  selectedLocation = decodeCompleteEncodedString(selectedLocation);

  // ! To Handle case FJ-1700 #Issue 2
  let trueValLocation = selectedLocation
    .replace(/null|@@@/g, '')
    .split(',')
    .map((value) => value.trim())
    .filter(Boolean);

  if (trueValLocation.length == 2) {
    selectedLocation = trueValLocation.join(',');
  }
  //  ! End Here FJ-1700 #Issue 2

  // Remove '@@@' and 'null' values from the selected location string
  const location = selectedLocation
    .replace(/null|@@@/g, '')
    .split(',')
    .map((value) => value.trim());

  let createCountryFormat = undefined;

  let filteredLocation = location.filter(Boolean);

  // Check if the location is US or Global
  let countryLocation = location[3]
    ? location[3]
    : filteredLocation.length == 1
    ? filteredLocation[0]
    : '';

  let isUSorGlobal =
    countryLocation &&
    (US_GLOBAL_LOCATION.US_LOC?.includes(countryLocation) ||
      US_GLOBAL_LOCATION.GLOBAL_LOC?.includes(countryLocation));

  if (countryLocation && !isUSorGlobal) {
    countryLocation = countryLocation.trim();
    isUSorGlobal = Object.values(US_STATES)?.includes(countryLocation);
  }

  /**
   * Check if it is a single location or missing country
   * filter(Boolean) removes empty strings from the array use case for ' , , @@@, United Kingdom'
   *  Now to identify if the location is Internation we are doing this
   */
  const isSingleLocation =
    selectedLocation.split(',').length <= 1 ||
    location.filter(Boolean).length == 1;
  const isMissingCountry = !location || !location[3];

  if (!isUSorGlobal && countyList && (isMissingCountry || isSingleLocation)) {
    // If countyList is available and it is a single location or missing country
    let countryName =
      selectedLocation.split(',').length <= 1
        ? selectedLocation
        : location[3] || location[0];

    // Find the country format from countyList
    createCountryFormat = countyList?.find(
      (item: any) =>
        item.countryName.toLowerCase() === countryName.trim().toLowerCase()
    );

    // If country format is found, update locationObj with countryName
    if (createCountryFormat && createCountryFormat.countryName) {
      locationObj = {
        name: '',
        cityName: '',
        stateName: '@@@',
        countryName: createCountryFormat.countryName,
      };
    } else {
      createCountryFormat = undefined;
      locationObj = {
        name: selectedLocation,
        cityName: '',
        stateName: '',
        countryName: '',
      };
    }
  } else {
    locationObj = {
      name: selectedLocation,
      cityName: '',
      stateName: '',
      countryName: '',
    };
  }

  if (location.length > 4) {
    // ! To handle special case of location like Ulsan
    let decodeLoc = encodedString.split(',');
    locationObj = {
      name: decodeURIComponent(decodeLoc[0] || ''),
      cityName: decodeURIComponent(decodeLoc[1] || ''),
      stateName: decodeURIComponent(decodeLoc[2] || ''),
      countryName: decodeURIComponent(decodeLoc[3] || ''),
    };
  }

  // If createCountryFormat is not available and location length is 4, update locationObj with individual values
  if (!createCountryFormat && location.length === 4) {
    locationObj = {
      name: decodeURIComponent(location[0] || ''),
      cityName: decodeURIComponent(location[1] || ''),
      stateName: decodeURIComponent(location[2] || ''),
      countryName: decodeURIComponent(location[3] || ''),
    };
  }

  // Check if it is an international location
  isInternationalLocation = createCountryFormat ? true : false;
  locationNameAndState = `${locationObj.name.trim()}${
    locationObj.stateName.trim() !== '' &&
    locationObj.stateName.trim() !== '@@@'
      ? ', ' + locationObj.stateName.trim()
      : ''
  }`;
  return { locationObj, isInternationalLocation, locationNameAndState };
};

function sanitizeSearchParams(
  searchParams: URLSearchParams,
  allowedTags: string,
  vulnerableParams: VulnerableParams
): URLSearchParams {
  Array.from(searchParams.entries()).forEach(([key, value]) => {
    const sanitizedValue = identifyAndRemoveVulnerableText(
      value,
      allowedTags,
      vulnerableParams
    );
    searchParams.set(key, sanitizedValue);
  });
  return searchParams;
}
export const getURLSearchParams = (query: any, searchKeyWord: string) => {
  let searchParams = new URLSearchParams(query);
  const vulnerableParams: VulnerableParams = {
    removeVulnerableText: true,
    isVulnerableTextPresent: false,
  };
  searchParams = sanitizeSearchParams(searchParams, '', vulnerableParams);
  // Job Locations can be multiple values
  if (searchKeyWord === 'joblocations') {
    let paramValue: string[] = searchParams.getAll(searchKeyWord) || '';
    paramValue = paramValue.filter(
      (item) =>
        item !== LOCATION_CHECK_BOX_VALUE.US &&
        item !== LOCATION_CHECK_BOX_VALUE.WORLD
    );
    return paramValue[0] || '';
  } else {
    let paramValue: string = searchParams.get(searchKeyWord) || '';
    return paramValue;
  }
};

export const appendQueryParam = (
  url: string,
  paramName: string,
  paramValue: string | number
): string => {
  const delimiter = url?.includes('?') ? '&' : '?';
  return `${url}${delimiter}${paramName}=${paramValue}`;
};

export const clearCookies = () => {
  const cookiesToPreserve = [
    'visitinfo',
    'vstr',
    'vsuid',
    'show_cookie_consent_banner',
    'softRegPopupDisplayed',
    'theme',
  ];
  if (typeof window === 'object' && window.document.cookie) {
    window.document.cookie.split(';').forEach(function (cookie) {
      const cookieName = cookie.split('=')[0].trim();
      // console has been added on purpose will be removed later //
      if (!cookiesToPreserve?.includes(cookieName)) {
        document.cookie = cookie
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      }
    });
  }
};
export const clearlocalStorage = () => {
  try {
    const keysToKeep = [
      'COOKIE_CONSENT',
      'conducted_experiments',
      'uiexp_conducted_experiments',
      'uiexp_visitor_layeruid',
      'webinarPopUpShown',
    ];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i) || '';

      // Check if the key is not in keysToKeep
      if (!keysToKeep?.includes(key)) {
        // Remove the item from localStorage
        try {
          localStorage.removeItem(key);
        } catch (e) {
          console.error('error during local storage remove item method', e);
        }
      }
    }
  } catch (error) {
    // Empty catch block
    // This catch block intentionally left empty
  }
};
export const deleteSingleCookie = (cookieName: string) => {
  if (typeof window === 'object' && window.document.cookie) {
    document.cookie =
      cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
};

export const getUsCityNameFromConst = (query: any = {}) => {
  if (
    query.category &&
    query.locationRoot &&
    query.category.toLowerCase() === 'usa' &&
    query.locationChild
  ) {
    const matchedCity = US_CITIES.find(
      (c) =>
        getSlug(c.city) === getSlug(query.locationChild) &&
        getSlug(c.code) === getSlug(query.locationRoot)
    );
    return matchedCity?.city ?? null;
  }
};

export const getPrefilledLocation = (query: any, title: string) => {
  if (query.category && query.locationRoot && query.locationChild) {
    // US city OR Canadian State (Location sub-category page)
    if (
      typeof query.category === 'string' &&
      query.category.toLowerCase() === 'usa' // US City case
    ) {
      const cityName = getUsCityNameFromConst(query);
      const stateName: string =
        typeof query.locationRoot === 'string'
          ? US_STATES[query.locationRoot]
          : 'State';
      const selectedLocation = stateName?.includes(' ')
        ? `${cityName}, ${stateName}`
        : `${cityName}, ${stateName}`;
      return { location: selectedLocation, country: '' };
    } else {
      // Canadian State case
      return { location: title ? title.split(',')[0] : '', country: '' };
    }
  } else if (query.category && query.locationRoot) {
    // US State OR A Country (Location root page)
    if (
      query.category.toLowerCase() === 'usa' &&
      query.locationRoot.toLowerCase() !== 'us-national'
    ) {
      // US State
      return { location: title, country: '' };
    } else {
      // International Location
      switch (query.locationRoot.toLowerCase()) {
        // case 'canada': // FJ-1281-Fix-Treat-Canada-same-as-other
        //   return { location: 'Canada', country: '' };
        // case 'united-kingdom':
        //   return { location: 'United Kingdom', country: '@@@' };
        case 'other':
          return { location: '', country: '@@@' };
        case 'anywhere':
        case 'us-national':
          return { location: '', country: '' };
        default:
          return { location: '', country: title };
      }
    }
  }
};

export const getStateCodeFromName = (stateName: string) => {
  for (let i in US_STATES) {
    if (stateName.toLocaleLowerCase() === US_STATES[i].toLocaleLowerCase())
      return i;
  }
  return '';
};

export const getJobLocationQueryString = (query: any) => {
  if (query.category && query.locationRoot && query.locationChild) {
    // US city OR Canadian State (Location sub-category page)
    if (
      typeof query.category === 'string' &&
      query.category.toLowerCase() === 'usa' &&
      query.locationRoot.toUpperCase() in US_STATES // US City case
    ) {
      let city = query.locationChild;
      if (city.toLocaleLowerCase() in locationQueryMapping) {
        city = locationQueryMapping[city.toLocaleLowerCase()];
      }
      return `${city.replace(/-/g, ' ')}, null, ${
        query.locationRoot
      }, United States`;
    } else if (
      typeof query.category === 'string' &&
      query.category.toLowerCase() === 'world' &&
      query.locationRoot.toLowerCase() === 'canada' // Canada State case
    ) {
      // Canadian State case
      return `${query.locationChild.replace(/-/g, ' ')}, null, @@@, Canada`;
    } else {
      // invalid URL structure. Should be in format /remote-jobs/world/Canada/... OR /remote-jobs/USA/.../...
      return '';
    }
  } else if (query.category && query.locationRoot) {
    // US State OR A Country (Location root page)
    if (
      query.category.toLowerCase() === 'usa' &&
      query.locationRoot.toLowerCase() !== 'us-national'
    ) {
      // US State
      const stateCode = getStateCodeFromName(
        query.locationRoot.replace(/-/g, ' ')
      );
      return `null, null, ${stateCode}, United States`;
    } else {
      // International Location
      switch (query.locationRoot.toLowerCase()) {
        case 'other':
          return 'others';
        case 'anywhere':
          return 'Work From Anywhere';
        case 'us-national':
          return 'Work from Anywhere in US';
        default: // Country case
          return `null, null, @@@, ${query.locationRoot.replace(/-/g, ' ')}`;
      }
    }
  }
};

export const removeDuplicatesArray = (arr: Array<any>): Array<any> => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const sendToSearchPage = (
  searchKeyword: string,
  self: boolean = true
) => {
  const path = `/search?searchkeyword=${encodeURIComponent(searchKeyword)}`;
  if (self) {
    window.location.href = path;
  } else {
    window.open(path, '_blank');
  }
};

export const sourceToDestRedirect = (source: string, param?: string) => {
  let destination = '';
  switch (source) {
    case 'from_hosted_fl':
      destination = `/login?dest=${param}`;
      break;
    case 'from_hosted':
      destination = `/publicjobs/${removeSpaceAndTabs(param as string)}`;
      break;
    case 'from_hosted_company_page':
      destination = `/publicjobs/${param}`;
      break;
  }
  return destination;
};

export const removeSpaceAndTabs = (str: string) => {
  const slug = str; //decodeCompleteEncodedString(str);
  return slug.replace(/%20/g, '').replace(/%09/g, '');
};

export const redirectToSource = (query: any) => {
  const dest = getURLSearchParams(query, 'dest');
  return dest ? dest : checkIsFJFeatureAllowed() ? '/members' : '/dashboard';
};

export const closeReconsiderModal = (setShowPopup: any) => {
  const popupstate = {
    content: 'true',
    expireatlogout: true,
    expired: false,
    expireseconds: 3600,
    created: Date.now(),
  };
  setClientSettings({
    key: _CONSTANTS.EXIT_POP_DISMISSED_KEY,
    value: JSON.stringify(popupstate),
  });
  setShowPopup(false);

  // track events
  closePopUpEvent(MODAL_CLOSE_POP_UP.exit_pop_up);
  genericClickEvent(
    POP_UP_EVENT.pop_up_close_event.event,
    POP_UP_EVENT.pop_up_close_event.properties
  );
  genericClickEvent(
    POP_UP_EVENT.registration_pop_up_closed.event,
    POP_UP_EVENT.registration_pop_up_closed.properties
  );
};

export const handleMouseMoveAway = async (
  e: any,
  setShowPopup: any,
  isPopUpRequired: boolean
) => {
  const fromTop = e.clientY <= 0;
  if (isPopUpRequired && fromTop) {
    setShowPopup(true);
  }
};

export const replaceUSNational = (locations: Array<string>) => {
  locations[locations.indexOf('US National')] = LOCATION_CHECK_BOX_VALUE.US;
  return locations;
};

export const removeNoEducation = (education: Array<string>): Array<string> => {
  return education.map((item) =>
    item === 'No Education Specified' ? '' : item
  );
};

export const getISO8601DatesDiff = (date: string) => {
  const currentDate = new Date();
  const previousDate = new Date(date);
  const timeDifferenceMs = Math.abs(
    currentDate.getTime() - previousDate.getTime()
  );
  const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
  return days;
};

export const formatDateFromISO = (dateString: string) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const updateJobIndexCheck = (
  customAttribute: number,
  jobNumber: number
) => {
  if (customAttribute) return customAttribute;
  return jobNumber;
};

export const mapIdTONameOfCategories = (
  jobsData: any,
  idOfSelectedCategory: string[]
) => {
  const allCategories = jobsData?.jobFilters?.filter(
    (category: any) => category.name === 'Category'
  )[0]?.category;
  if (!allCategories) return [];

  let selectedCategory = [];
  for (let category of allCategories) {
    if (idOfSelectedCategory?.includes(category.category.id.toString()))
      selectedCategory.push(
        category.category.name,
        ...category.subCategory.map((subcat: any) => subcat.name)
      );

    for (let subcat of category.subCategory)
      if (idOfSelectedCategory?.includes(subcat.id.toString()))
        selectedCategory.push(subcat.name);

    if (idOfSelectedCategory.length === selectedCategory.length)
      return selectedCategory;
  }
  return selectedCategory;
};

export const cleanUserCategories = (json: any) => {
  const JSRB = json.sections.filter(
    (section: any) => section.sectionTypeCD === SECTION_TYPE_CD.jobSearchBasics
  );
  let paras =
    JSRB[0]?.paragraphs[0]?.docDatas?.filter(
      (docData: any) => docData?.fieldCD === FIELD_TYPE_CD.jobCategory
    ) || [];
  if (paras.length > 5) {
    paras.length = 5;
  }
  return paras;
};

export const getTotalSubcategoriesAndCategory = (ids: any, jobFilters: any) => {
  let { category }: any =
    jobFilters?.find((i: any) => i.slug === 'categories') || [];
  let result = 0;
  for (const id of ids) {
    const category1 = category?.find((item: any) => item.category.id == id);
    if (category1) {
      const totalSubcategories = category1.subCategory.length;
      result = result + totalSubcategories + 1;
    } else {
      result = result + 1;
    }
  }
  return result > 0 ? result : '';
};
/**
 * Determines the status of the "Work Anywhere" checkbox based on the query parameters.
 * @param query - The query parameters.
 * @param us - The default value for the "US" checkbox.
 * @param world - The default value for the "World" checkbox.
 * @returns An object with the updated values for the "US" and "World" checkboxes.
 */

export const getWorkAnywhereCheckboxStatusV2 = (
  query: any,
  us: number = 0,
  world: number = 1
) => {
  const category =
    query.locationCountry?.toLowerCase() || query.category?.toLowerCase();
  const locationRoot = query.locationRoot?.toLowerCase();
  const anywhereinworld = query.anywhereinworld;
  const anywhereinus = query.anywhereinus;

  if (category === 'usa') {
    us = 1;
  }

  if (locationRoot === 'anywhere') {
    world = 1;
  } else if (locationRoot === 'other') {
    us = -1;
    world = -1;
  } else if (locationRoot === 'us-national') {
    us = 1;
    world = 0;
  }

  if (anywhereinworld === '0') {
    world = 0;
  } else if (anywhereinworld === '1') {
    world = 1;
  }

  if (anywhereinus === '0') {
    us = 0;
  } else if (anywhereinus === '1') {
    us = 1;
  }

  return { us, world };
};

export function loadJs(
  source: string,
  callback?: () => void,
  async?: boolean,
  defer?: boolean,
  crossorigin?: boolean
) {
  let r = false;
  const s: any = document.createElement('script');
  s.type = 'text/javascript';
  s.src = source;
  if (crossorigin) {
    s.crossOrigin = 'anonymous';
  }
  s.async = async != null && async != undefined ? async : true;
  s.defer = defer != null && defer != undefined ? defer : false;
  s.onload = s.onreadystatechange = function () {
    if (
      !r &&
      (!this.readyState ||
        this.readyState == 'complete' ||
        this.readyState == 'loaded')
    ) {
      r = true;
      callback && callback();
    }
  };
  const t = document.getElementsByTagName('script')[0];
  if (t && t.parentNode) {
    const parentNode = t.parentNode;

    if (parentNode.contains(t)) {
      parentNode.insertBefore(s, t);
    } else {
      console.error('The reference node is not a child of the parent node.');
    }
  } else {
    console.error('The link element or its parent node does not exist.');
  }
}

export function prefetchFiles(src: string, callback?: () => void): void {
  let r = false;
  const s: any = document.createElement('link');
  s.rel = 'prefetch';
  s.href = src;
  s.type = 'text/javascript';
  s.onload = s.onreadystatechange = function () {
    if (
      !r &&
      (!this.readyState ||
        this.readyState == 'complete' ||
        this.readyState == 'loaded')
    ) {
      r = true;
      callback && callback();
    }
  };
  const t = document.getElementsByTagName('link')[0];
  if (t && t.parentNode) {
    const parentNode = t.parentNode;

    if (parentNode.contains(t)) {
      parentNode.insertBefore(s, t);
    } else {
      console.error('The reference node is not a child of the parent node.');
    }
  } else {
    console.error('The link element or its parent node does not exist.');
  }
}

export const makeTitle = (queryParamsKeys: any) => {
  const prefixOBJ = {
    searchkeyword: queryParamsKeys?.searchkeyword
      ? '"' + queryParamsKeys?.searchkeyword + '"; '
      : '',
    joblocations: queryParamsKeys?.joblocations
      ? `are anywhere in ${queryParamsKeys?.joblocations}` + '; '
      : '',
    jobschedules: queryParamsKeys?.jobschedules
      ? queryParamsKeys.jobschedules + '; '
      : '',
    remoteoptions: queryParamsKeys?.remoteoptions
      ? queryParamsKeys.remoteoptions + '; '
      : '',
    jobtypes: queryParamsKeys?.jobtypes
      ? `are a job type of ${queryParamsKeys?.jobtypes}; `
      : '',
    careerlevel: queryParamsKeys?.careerlevel
      ? queryParamsKeys.careerlevel + '; '
      : '',
    educations: queryParamsKeys?.educations
      ? queryParamsKeys.educations + '; '
      : '',
    travels: queryParamsKeys?.travels
      ? `Travel Required: ${queryParamsKeys.travels}; `
      : '',
    categories: queryParamsKeys?.categories
      ? queryParamsKeys.categories + '; '
      : '',
    accolades: queryParamsKeys?.accolades
      ? `are in ${queryParamsKeys.accolades};`
      : '',
    jobtitles: queryParamsKeys?.jobtitles
      ? `have titles ${queryParamsKeys.jobtitles}; `
      : '',
  };
  let replaceMsg =
    prefixOBJ.searchkeyword +
    prefixOBJ.jobschedules +
    prefixOBJ.jobtypes +
    prefixOBJ.categories +
    prefixOBJ.remoteoptions +
    prefixOBJ.careerlevel +
    prefixOBJ.jobtitles +
    prefixOBJ.accolades;
  return replaceMsg;
};

export const closeFJDifferentModal = (setShowPopup: any) => {
  setShowPopup(false);
  // closePopUpEvent(MODAL_CLOSE_POP_UP.exit_pop_up);
};

export const makeFlexJobsWizardBoolean = (
  url: string | undefined,
  isPremium: boolean,
  isSearchPage: boolean = true,
  includeCheck: string = '/job_wizard/'
) => {
  let openFJDifferent = false;
  if (url && url?.includes(includeCheck)) {
    openFJDifferent = true;
  }

  if (url?.includes('/remote-jobs/company')) {
    openFJDifferent = false;
  }
  if (sessionStorageGetItem(_CONSTANTS.FLEXJOBS_DIFFERENT_POP_UP_KEY)) {
    openFJDifferent = false;
  }
  return isSearchPage && !isPremium ? openFJDifferent : false;
};
export const isCurrentPageInList = (router: any, urls: Array<string>) => {
  let shouldLoad = false;
  let asPath = router.asPath || '';
  let basePath = router.basePath || '';
  let pathName = router.pathname || '';
  asPath = basePath + asPath;
  if (asPath) {
    asPath = asPath.toLowerCase();
    urls.forEach((m) => {
      if (
        !shouldLoad &&
        (asPath == m.toLowerCase() ||
          pathName == m.toLowerCase() ||
          asPath.indexOf(m.toLowerCase()) > -1)
      ) {
        shouldLoad = true;
      }
    });
  }
  return shouldLoad;
};

export const isOnlySavedSearchQueryParam = (): boolean => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Get all query parameters
  const queryParams = urlSearchParams.getAll('savedsearch');

  // Check if 'savedsearch' is the only query parameter
  return (
    queryParams.length === 1 &&
    urlSearchParams.toString() ===
      'savedsearch=' + encodeURIComponent(queryParams[0])
  );
};

export const setPaymentAndSubscriptionStatus = async () => {
  if (window) {
    const userStatusCookie = readCookie(_CONSTANTS.USER_STATUS);
    if (userStatusCookie) {
      const userStatus = parseJSON(
        userStatusCookie,
        logErrorServer,
        true,
        'setPaymentAndSubscriptionStatus function',
        'FJ_JOBS'
      );
      const isLoggedInStatus = userStatus.IsUserLoggedIn;
      const ecomStatus = await getEcomFlowData(userStatus.User.UserId);
      localStorageSetItem(
        'paymentStatus',
        ecomStatus && ecomStatus?.data?.paymentStatus!
      );
      localStorageSetItem(
        'subscriptionStatus',
        ecomStatus && ecomStatus?.data?.subscriptionStatus!
      );
      if (!isLoggedInStatus) {
        localStorageRemoveItem('paymentStatus');
        localStorageRemoveItem('subscriptionStatus');
      }
    }
  }
};

export const sortJobCategories = (jobCategories: any) => {
  jobCategories.data?.sort((a: any, b: any) => {
    if (a.category.name.toLowerCase() > b.category.name.toLowerCase()) return 1;
    if (a.category.name.toLowerCase() < b.category.name.toLowerCase())
      return -1;
    return 0;
  });
  jobCategories.data.map((jc: any) => sortSubcategories(jc.subCategory));
};

export const sortSubcategories = (subCategories: any) => {
  subCategories?.sort((a: any, b: any) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });
};

export function getQSValue(key: string) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params[key];
}
export const sendToCompanySearch = (
  searchParam: string,
  self: boolean = true
) => {
  const path = searchParam
    ? `/telecommute-jobs-by-company/search?search=${encodeURI(searchParam)}`
    : '/telecommute-jobs-by-company.aspx';
  if (self) {
    window.open(path, '_self');
  } else {
    window.open(path, '_blank');
  }
};

export const getfullUrl = (
  req: any,
  url?: string,
  urlToLowerCase: boolean = false
): string => {
  let fullUrl = '';
  const rootDomain = process.env.NEXT_PUBLIC_ROOT_URL;
  let path = url || req?.url;
  if (urlToLowerCase) path = path.toLowerCase();
  if (req?.url) fullUrl = rootDomain + path.split('?')[0].split('#')[0];

  return fullUrl;
};

export function checkForSavedSearchView(query: any) {
  delete query.sortbyposteddate; // delete date/relevance check key
  delete query.anywhereinus;
  delete query.anywhereinworld;
  delete query.page;
  // Check if only 'savedsearch' and/or 'joblocations' keys exist
  const keys = Object.keys(query);
  if (keys.length === 1 && keys?.includes('savedsearch')) {
    return true;
  } else if (
    keys.length === 2 &&
    keys?.includes('savedsearch') &&
    keys?.includes('joblocations')
  ) {
    // Check if 'joblocations' has exactly two specific values
    const jobLocations = query.joblocations;
    if (
      Array.isArray(jobLocations) &&
      jobLocations.length === 2 &&
      jobLocations?.includes('Work from Anywhere in US') &&
      jobLocations?.includes('Work from Anywhere')
    ) {
      return true;
    }
  }

  return false;
}

export function getCurrentMonthAndYear() {
  const date = new Date();
  return `${date.toLocaleString('en', {
    month: 'short',
  })} ${date.toLocaleString('en', {
    year: 'numeric',
  })}`;
}

export function showCountryFlag(
  countries: string[],
  regionID: string[],
  city: string[],
  state: string[]
) {
  let usa = {};
  let canada = {};
  let aus = {};
  let india = {};
  let uk = {};
  let anywhere = {};
  let isRegion = {};
  let isCity = {};
  let other = {};

  countries?.forEach((country) => {
    switch (country.toLowerCase()) {
      // case 'usa':
      // case 'united states':
      //   usa = {
      //     icon: 'icon-usa.png',
      //     title: 'Job open to candidates located anywhere in the US',
      //   };
      //   break;
      case 'canada':
        canada = {
          icon: 'icon-canada.png',
          title: 'Job open to candidates located anywhere in the US',
        };
        break;
      case 'india':
        india = {
          icon: 'india.png',
          title: 'Job open to candidates located anywhere in the US',
        };
        break;
      case 'united kingdom':
        uk = {
          icon: 'uk.png',
          title: 'Job open to candidates located anywhere in the US',
        };
        break;
      case 'australia':
        aus = {
          icon: 'australia.png',
          title: 'Job open to candidates located anywhere in the US',
        };
        break;
    }
  });

  regionID?.forEach((region) => {
    switch (String(region)) {
      case '1':
        usa = {
          icon: 'icon-usa.png',
          title: 'Job open to candidates located anywhere in the US',
        };
        break;
      case '2':
      case '3':
      case '4':
      case '5':
      case '7':
      case '9':
      case '10':
        isRegion = {
          icon: '',
          title: 'Candidates need to be able to work in the Region specified',
        };
        break;
      case '6':
        anywhere = {
          icon: 'icon-everywhere.png',
          title: 'Job open to candidates located anywhere',
        };
        break;
    }
  });

  if (city?.filter(Boolean).length || state?.filter(Boolean).length) {
    isCity = {
      icon: 'icn-map-marker.png',
      title:
        'Candidates need to be able to work in the City or State specified',
    };
  }

  const str = [isCity, other, usa, canada, aus, india, uk, anywhere, isRegion];
  const filteredData = str.filter((obj) => Object.keys(obj).length !== 0);
  return filteredData;
}

const getMaxCountForCategory = (
  categoryData: any,
  categoriesWithCount: any
) => {
  return Math.max(
    categoriesWithCount[categoryData.category.name.toLowerCase()] || 0,
    ...categoryData.subCategory.map(
      (subCategory: any) =>
        categoriesWithCount[subCategory.name.toLowerCase()] || 0
    )
  );
};

const sortCategoriesOnJobCount = (
  categoriesData: any,
  categoriesWithCount: any
) => {
  const categoriesWithCountLower: { [key: string]: any } = Object.keys(
    categoriesWithCount
  ).reduce((acc: { [key: string]: any }, key) => {
    acc[key.toLowerCase()] = categoriesWithCount[key];
    return acc;
  }, {});

  return categoriesData.sort((a: any, b: any) => {
    const aCount = getMaxCountForCategory(a, categoriesWithCountLower);
    const bCount = getMaxCountForCategory(b, categoriesWithCountLower);
    return bCount - aCount;
  });
};

export function filterDropItems({
  filterData,
  categoriesData,
  query,
}: {
  filterData: any;
  categoriesData: any;
  query?: any;
}) {
  if (filterData && Array.isArray(filterData)) {
    filterData = filterData.filter((i: any) => i.name !== 'Accolades');
    filterData.forEach((item: any) => {
      if (filterDropOrder[item.name]) {
        // ! Special Case for category as we are getting from separate API
        if (item.name === 'Category' && categoriesData) {
          if (query?.includes('searchkeyword')) {
            item.category = sortCategoriesOnJobCount(
              categoriesData,
              item.categoryWithCount
            );
          } else {
            item.category = categoriesData;
          }
          item.categoryWithCount = {};
        } else {
          item.categoryWithCount = filterDropOrder[item.name];
        }
      }
    });
  }
  return filterData;
}

export const formatSalaryRange = (salaryString: string): string => {
  if (typeof salaryString === 'string' && salaryString.length > 2) {
    salaryString = salaryString?.includes('Hourly')
      ? salaryString
      : salaryString.replace(/\.00/g, '');
  }
  return salaryString;
};

export function getIdsForPage(
  pageNumber: number = 1,
  itemsPerPage: number,
  ids: number[]
): number[] {
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return ids.slice(startIndex, endIndex);
}

export function hasKeywordInQuery(router: any, searchItem: string[]) {
  if (!router && !router.query) {
    return false;
  }
  return Object.keys(router.query).some((key) => searchItem?.includes(key));
}

export function dynamicValueAssign(
  name: string,
  dynamicValues: Array<string> | undefined
) {
  if (Array.isArray(dynamicValues) && dynamicValues.length > 0) {
    for (let i = 0; i < dynamicValues.length; i++) {
      name = name.replace(new RegExp(`\\{${i}\\}`, 'g'), dynamicValues[i]);
    }
  }
  return name || '';
}

export function modifyQueryLocation(
  locationObj: any,
  isInternationalLocation: boolean,
  query: any
) {
  if (isInternationalLocation) {
    query['anywhereinus'] = '0';
    // !FJ-857- If user selects 'No Remote Work' and location is international 'anywhereinworld' to 0
    if (query.remoteoptions && query.remoteoptions == 'No Remote Work') {
      query['anywhereinworld'] = '0';
    }
  }

  // ! Commented below code due to changes related to FJ-1474
  // if (query.joblocations) {
  //   query[
  //     'joblocations'
  //   ] = `${locationObj.name}, ${locationObj.cityName},${locationObj.stateName},${locationObj.countryName}`;
  // }

  return query;
}

export const extractActualSavedSearchValue = (
  url: string,
  encode?: boolean
) => {
  const regex = /[?&]savedsearch=([^&]*)/;
  const match = regex.exec(url);
  return match && (encode ? encodeURIComponent(match[1]) : match[1]);
};

export const localStorageGetItem = (key: string) => {
  let result: string | null = '';
  try {
    if (typeof window === 'object' && window.localStorage) {
      result = window.localStorage.getItem(key);
    }
    return result;
  } catch (error) {
    result = '';
  }
  return result;
};

export const localStorageSetItem = (key: string, value: string) => {
  try {
    if (typeof window === 'object' && window.localStorage) {
      window.localStorage.setItem(key, value);
    }
  } catch (error) {
    // Empty catch block
    // This catch block intentionally left empty
  }
};

export const localStorageRemoveItem = (key: string) => {
  try {
    if (typeof window === 'object' && window.localStorage) {
      window.localStorage.removeItem(key);
    }
  } catch (error) {
    // Empty catch block
    // This catch block intentionally left empty
  }
};

export const checkIsServerCall = (baseUrl: string = ''): string => {
  if (typeof window === 'undefined') {
    const key = Object.keys(ApiBaseUrl).find((k) => ApiBaseUrl[k] === baseUrl);
    return key ? ApiBaseUrlServer[key] : baseUrl;
  } else {
    return baseUrl;
  }
};

export const isServerCall = () => {
  return typeof window === 'undefined' ? true : false;
};

export const localStorageSetItemExpiry = (
  key: string,
  value: string,
  expiryInHours: number
) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + expiryInHours * 60 * 60 * 1000,
  };

  try {
    if (typeof window === 'object' && window.localStorage) {
      window.localStorage.setItem(key, JSON.stringify(item));
    }
  } catch (error) {
    // Empty catch block
    // This catch block intentionally left empty
  }
};

export const genrateGUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};
export const updateJobIdInCookie = (id: string) => {
  // Retrieve existing cookie value
  const cookieValue =
    document.cookie
      .split('; ')
      .find((row) => row.startsWith(`tempjobIds=`))
      ?.split('=')[1] || '[]';

  // Parse existing cookie value into an array
  let jobIds: string[] = parseJSON(
    cookieValue,
    logErrorServer,
    true,
    'updateJobIdInCookie',
    'FJ_JOBS'
  );

  // Check if the provided ID already exists in the array
  if (!jobIds?.includes(id)) {
    // If the ID doesn't exist, add it to the array
    jobIds.push(id);

    // Update the cookie with the updated array value
    document.cookie = `tempjobIds=${JSON.stringify(jobIds)}; path=/`;
  }
};

export const acceptOnlyFilterdKeys = (data: any = {}): object => {
  const filteredData = Object.fromEntries(
    Object.entries(data).filter(([key]) => selectedFiltersOrder?.includes(key))
  );

  return filteredData;
};

export const getCookieStartingWith = (prefix: string): string | null => {
  if (typeof window === 'object' && window?.document?.cookie) {
    const cookies = window.document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(prefix)) {
        return cookie;
      }
    }
  }

  return null;
};

export const getClientIdSessionId = (): {
  client_id: string | null;
  session_id: string | null;
} => {
  let clientId = readCookie('_ga');
  let sessionId = getCookieStartingWith('_ga_');

  if (clientId) {
    clientId = clientId.split('GA1.1.')[1];
  }
  if (sessionId) {
    sessionId = sessionId.split('.')[2];
  }
  return {
    client_id: clientId,
    session_id: sessionId,
  };
};

export const createCookie = (
  key: string,
  value: string,
  expirationDays?: number,
  path?: string,
  domain?: string
) => {
  if (window && window.document) {
    let cookieString =
      encodeURIComponent(key) + '=' + encodeURIComponent(value);

    cookieString += '; path=' + '/';

    if (expirationDays) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + expirationDays);
      cookieString += '; expires=' + expirationDate.toUTCString();
    }

    if (path) {
      cookieString += '; path=' + path;
    }

    if (domain) {
      cookieString += '; domain=' + domain;
    }

    document.cookie = cookieString;
  }
};

export const sessionStorageGetItem = (key: string) => {
  let result: string | null = '';
  try {
    if (typeof window === 'object' && window.sessionStorage) {
      result = window.sessionStorage.getItem(key);
    }
    return result;
  } catch (error) {
    result = '';
  }
  return result;
};

export const sessionStorageSetItem = (key: string, value: string) => {
  try {
    if (typeof window === 'object' && window.sessionStorage) {
      window.sessionStorage.setItem(key, value);
    }
  } catch (error) {
    // Empty catch block
    // This catch block intentionally left empty
  }
};

export const sessionStorageRemoveItem = (key: string) => {
  try {
    if (typeof window === 'object' && window.sessionStorage) {
      window.sessionStorage.removeItem(key);
    }
  } catch (error) {
    // Empty catch block
    // This catch block intentionally left empty
  }
};

export const addToCookieHeader = (
  key: string,
  value: string,
  cookieHeader?: string
) => {
  if (typeof cookieHeader === 'string' && cookieHeader.length > 0) {
    return `${cookieHeader}; ${key}=${value}`;
  } else {
    return `${key}=${value}`;
  }
};

export const updateJobCountLocalStorage = (key: string, count: number) => {
  const jobsCountStr = localStorageGetItem(_CONSTANTS.JOBS_COUNT);
  if (jobsCountStr) {
    let jobsCountObj = parseJSON(
      jobsCountStr,
      logErrorServer,
      true,
      'updateJobCountLocalStorage Function',
      'FJ_JOBS'
    );
    jobsCountObj[key] = `${count}`;
    localStorageSetItem(_CONSTANTS.JOBS_COUNT, JSON.stringify(jobsCountObj));
  }
};

export const updateUserPreferenceValue = async (
  userId: string,
  preferenceCd: string,
  value: string
) => {
  const response = await updateUserPreferences(userId, preferenceCd, value);
  if (response.success) {
    let allUserPreferences =
      getClientSettingValue(LOCAL_STORAGE_CONSTS.userPreferences) ?? 'null';
    if (!allUserPreferences) {
      allUserPreferences = [];
    } else {
      allUserPreferences = allUserPreferences.filter(
        (a: any) => a?.code !== preferenceCd
      );
    }
    allUserPreferences.push({
      code: preferenceCd,
      value: value,
    });
    setClientSettings({
      key: LOCAL_STORAGE_CONSTS.userPreferences,
      value: allUserPreferences,
    });
  }
};

export const getAllCookieNames = (cookieHeader: string): string => {
  if (cookieHeader) {
    return Object.keys(
      Object.fromEntries(cookieHeader.split('; ').map((c) => c.split('=')))
    ).join(', ');
  }

  return '';
};

export const getCookieValue = (name: string, cookieHeader: string): any => {
  return (
    cookieHeader.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  );
};

export const localJobCountUpdate = (data: any): void => {
  if (typeof data?.jobsCount === 'number') {
    const flexJobsNumber = Math.floor(data.jobsCount);
    localStorageSetItem(
      LocalStorageKeys.TotalJobsCount,
      JSON.stringify({
        value: flexJobsNumber.toLocaleString('en-US'),
        updatedAt: new Date(),
      })
    );
  }
};

export const isUpdateLocalJobCount = (): boolean => {
  if (
    localStorageGetItem(LocalStorageKeys.TotalJobsCount) === null ||
    localStorageGetItem(LocalStorageKeys.TotalJobsCount) === undefined
  ) {
    return true;
  } else {
    const localValue = localStorageGetItem(LocalStorageKeys.TotalJobsCount);
    const parsedValue: ILocalJobCount = parseJSON(
      localValue || '{}',
      logErrorServer,
      true,
      'isUpdateLocalJobCount function',
      'FJ_JOBS'
    );
    const differenceInMinutes: number = Math.round(
      (new Date().getTime() - new Date(parsedValue.updatedAt).getTime()) / 60000
    );

    if (isNaN(differenceInMinutes) || differenceInMinutes > 30) {
      return true;
    } else {
      return false;
    }
  }
};

export const divideColumns = (topSearchEntries: Array<Array<String>>) => {
  const middleIndex = Math.floor(topSearchEntries.length / 2);

  const leftCol = topSearchEntries
    .slice(0, middleIndex)
    .map(([key, value]) => ({
      key: key,
      value: value,
    }));

  const rightCol = topSearchEntries.slice(middleIndex).map(([key, value]) => ({
    key: key,
    value: value,
  }));
  return { leftCol, rightCol };
};

export const logApiResponseTime = async (
  apiCallPromise: any,
  apiName: string = ''
) => {
  const startTime = performance.now();
  const results = await apiCallPromise;
  const endTime = performance.now();
  getApiCallDuration(endTime, startTime, apiName);
  return results;
};
export const makePostedDate = (
  jobDetails: JobDetailsResults,
  message: string
) => {
  const jobExpireDate = new Date(jobDetails?.expireOn || '');
  const currentDate = new Date();
  let jobExpired: boolean | string = false;
  if (jobExpireDate < currentDate) {
    jobExpired = `${message} ${formatDate(String(jobExpireDate))}`;
  }
  return jobExpired;
};

export const getNumFormatter = () => {
  let userLocale = 'en-US';
  if (typeof navigator !== 'undefined')
    userLocale = navigator.language || 'en-US';

  return new Intl.NumberFormat(userLocale);
};

export const isExpired = (expireOn: string) => {
  const d = new Date(expireOn);
  // @ts-ignore
  if (d instanceof Date && !isNaN(d)) {
    return d < new Date();
  }
  return false;
};

export const getCdnExpVarint = (res: ServerResponse): number => {
  let variant: number = 0;
  if (res.hasHeader('X-Cache-Key:')) {
    const headerValue = res.getHeader('X-Cache-Key:');
    if (typeof headerValue === 'string') {
      const headerParams: Array<string> = headerValue
        ?.split('?')?.[1]
        ?.split('&');
      headerParams?.forEach((elem) => {
        if (elem?.includes('vID')) {
          const value = elem?.split('=');
          variant = Number(value?.[1] || 0);
        }
      });

      return variant;
    }
  }
  return variant;
};

// TODO: Move to commonbuilder when ecom exp is baselined
export const updateSearchActivity = (updateObj: any) => {
  let searchActivity =
    parseJSON(
      localStorageGetItem('searchActivity'),
      logErrorServer,
      false,
      'updateSearchActivity',
      'FJ_JOBS'
    ) || {};
  searchActivity = {
    ...searchActivity,
    ...updateObj,
  };
  localStorageSetItem('searchActivity', JSON.stringify(searchActivity));
};

export const checkIsFJFeatureAllowed = () => {
  return process.env.NEXT_PUBLIC_ENABLE_FJ_FEATURE === 'false' ? false : true;
};

export const checkForGuid = (slug: string) => {
  const uuid = slug.match(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
  )?.[0];
  return uuid;
};

export const jobCountFilters = (
  filterdKeysOnly: Array<any>,
  selectedFilterName: any,
  router: any,
  selectedCountry: any,
  inputParam: any,
  location: any
) => {
  let selectedFilters: string = '';
  if (filterdKeysOnly.length > 0) {
    selectedFilters = selectedFilterName.join('; ');
  }

  let locationString = '';
  if (router.query.joblocations || location) {
    if (selectedCountry) {
      locationString += selectedCountry;
      if (inputParam.location) {
        locationString += `; ${inputParam.location}`;
      }
    } else {
      locationString = inputParam.location || '';
    }
  }

  return selectedFilters && locationString
    ? `${selectedFilters}; ${locationString}`
    : selectedFilters || locationString;
};

export const getHostValue = (hostVal: string) => {
  switch (hostVal) {
    case 'jobs.remote.co':
      return 'remote.co';
    case 'qa-jobs.remoteco-stg.com':
      return 'qa.remoteco-stg.com';
    case 'reg-jobs.remoteco-stg.com':
      return 'reg.remoteco-stg.com';
    case 'stg-jobs.remoteco-stg.com':
      return 'stg.remoteco-stg.com';
    default:
      return 'remote.co';
  }
};

export const getRmcCategories = (
  categories: Array<Categories>
): Array<Categories> => {
  let rmcJobCategories: Array<Categories> = [];

  categories.map((cat) => {
    let rmcCategory = rmcCategories.find(
      (rmcCat) => rmcCat.category.slug == cat.slug
    );
    if (rmcCategory) {
      rmcJobCategories.push({
        name: rmcCategory.category.name,
        slug: rmcCategory.category.slug,
        imgSrc: rmcCategory.category.icon,
      });
    } else {
      rmcCategories.map((rmcCat) => {
        const foundCat = rmcCat.category.fjCategorySlug.find(
          (slug) => slug == cat.slug
        );

        if (foundCat) {
          rmcJobCategories.push({
            name: rmcCat.category.name,
            slug: rmcCat.category.slug,
            imgSrc: rmcCat.category.icon,
          });
        }
      });
    }
  });
  const uniqueRmcJobCategories = rmcJobCategories.filter(
    (rmcCategory, index) => {
      const _categories = JSON.stringify(rmcCategory);
      return (
        index ===
        rmcJobCategories.findIndex((category) => {
          return JSON.stringify(category) === _categories;
        })
      );
    }
  );
  return uniqueRmcJobCategories;
};

export const getEJendPoints = (): typeof ejEndpoints => {
  return structuredClone(ejEndpoints);
};

export const getEBendPoints = (): typeof ebEndpoints => {
  return structuredClone(ebEndpoints);
};

export const checkIsFreeJobAvailable = (jobDetailsObj: any) => {
  const isFreeJob = jobDetailsObj?.isFreeJob || false;
  const jobDetails = {
    ...jobDetailsObj,
    description: jobDetailsObj?.description
      ? jobDetailsObj.description
          .replace(/<h1>/g, '<h3>')
          .replace(/<\/h1>/g, '</h3>')
      : null,
  };

  return {
    isFreeJob,
    jobDetails,
  };
};

export const makeOverViewText = (
  metaIdentifier: string | string[] | undefined
) => {
  let overviewText;
  if (metaIdentifier) {
    switch (metaIdentifier) {
      case 'part-time':
        overviewText =
          'Discover the flexibility of part-time remote jobs, a top choice for people looking to work from home or work from anywhere while limiting the amount of hours they work. Also popular among freelancers, online part-time jobs are ideal for people seeking a side hustle to earn extra income, dipping their toes in a new career, or trying to balance work with other commitments and interests. Work from home part-time jobs are available in various industries. Check out the latest virtual part-time jobs and companies hiring!';
        break;
      case 'full-time':
        overviewText =
          'Find the best full-time remote jobs here. Interested in continuing your career while working remotely? More companies every day are offering full-time opportunities that are completely remote. From developers to customer service agents, full-time remote opportunities are plentiful if you have the right experience.';
        break;
      case 'freelance':
        overviewText =
          'Discover the flexibility of online freelance jobs, a popular choice for those looking to set their own hours or work from anywhere. Remote freelance jobs provide flexible schedules, whether full-time or part-time, and the freedom to choose where you work. Available in various fields for self-motivated professionals, work from home freelance jobs also provide the flexibility to work multiple jobs, and the hiring process is often quick. Check out the latest online freelance jobs and companies hiring now!';
        break;
      case 'entry-level':
        overviewText =
          'Discover the flexibility of entry-level remote jobs! Popular among people who are new to the workforce or transitioning into a new career, online entry-level jobs require minimal prior work experience and provide opportunities to build skills. Virtual entry-level jobs enable the freedom to work at home or work from anywhere and are available as full-time, part-time, and freelance opportunities. Work from home entry-level jobs are found in various career fields. Check out the latest remote, entry-level jobs and companies hiring!';
        break;
      default:
        overviewText = undefined;
    }
  }
  return overviewText;
};

export const response410 = (res: any, errorText: string) => {
  logErrorServer(`FXJ RMC_JOBS Server error`, errorText, true);
  res.statusCode = 410;
  return {
    props: {},
  };
};

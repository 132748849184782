import { JobsCategories } from '@components/shared.types';
import { Button } from '@license-admin/boldfjcomponents';
import { isRCPortal } from 'helper';
import { useOutsideClick } from 'helper/hooks/useOutsideClick';
import React, { useEffect, useRef } from 'react';
import {
  BtnGroup,
  BtnWrap,
  Checkbox,
  CrossIcon,
  DropCatLink,
  DropCatList,
  DropCatListItem,
  DropdownList,
  IconWrap,
} from './styled';

interface Props {
  children?: React.ReactNode;
  name: string;
  slug: string;
  active?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
  className: string;
  bgColor?: string;
  boxShadow?: string;
  position?: string;
  onToggle?: () => void;
  categoryWithCount: CategoryWithCount;
  removeFilter: (_slug: string) => void;
  handleFilterChange: (
    _filterName: string,
    _filterCategory: string,
    _name: string
  ) => string;
  selectedCategories: Array<string>;
  category?: JobsCategories[];
  handleCategoryClick?: () => void;
  handleCategoryFilter: (
    _filter: any,
    _categoryId: string,
    _categoryType: 'Parent' | 'Child'
  ) => void;
  selectedFilters: any;
  setIsActive?: any;
  filterRef?: any;
  isRCPortal?: boolean;
}

type CategoryWithCount = {
  [key: string]: number;
};

const FilterBtnGroup = (props: Props) => {
  const selectedCategories =
    props.selectedCategories && props.selectedCategories.length;

  const selectedCategoryName = Array.isArray(props.selectedCategories)
    ? props.selectedCategories[0]
    : props.selectedCategories;

  const changeHandler = (
    filterName: string,
    filterCategory: string,
    name: string
  ) => {
    document.location.href = props.handleFilterChange(
      filterName,
      filterCategory,
      name
    );
  };
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isOutsideClick = useOutsideClick(dropdownRef, [props.filterRef]);

  useEffect(() => {
    if (isOutsideClick) {
      props.setIsActive(null);
    }
  }, [isOutsideClick]);

  const filterDropdown = (filter: any) => {
    const isParentActive =
      props.selectedCategories &&
      props.selectedCategories?.includes(JSON.stringify(filter.category.id));

    return (
      <DropCatList>
        {filter.subCategory.length > 0 &&
          filter.subCategory.map((filterSub: any, index: any) => (
            <DropCatListItem key={`subcat-${index}`} className="sub-cat">
              <DropCatLink
                onClick={() =>
                  props.handleCategoryFilter(
                    filter,
                    JSON.stringify(filterSub.id),
                    'Child'
                  )
                }
                id={`catlink-${filterSub.name.replace(/\s+/g, '-')}-${index}`}
              >
                <Checkbox
                  type="checkbox"
                  checked={
                    isParentActive
                      ? isParentActive
                      : props.selectedCategories &&
                        props.selectedCategories?.includes(
                          JSON.stringify(filterSub.id)
                        )
                  }
                  id={`checkbox-${filterSub.name.replace(
                    /\s+/g,
                    '-'
                  )}-${index}`}
                />
                {filterSub.name}
              </DropCatLink>
            </DropCatListItem>
          ))}
      </DropCatList>
    );
  };

  function findNameById(id: any, data: any): string {
    let categoryId = Array.isArray(id) ? id[0] : [id][0];
    for (const item of data) {
      if (item.category.id == categoryId) {
        return item.category.name + `${id.length > 1 ? '...' : ''}`;
      }
      for (const subCategory of item.subCategory) {
        if (subCategory.id == categoryId) {
          return (
            subCategory.name +
            `${
              Array.isArray(props.selectedCategories) &&
              props.selectedCategories.length > 1
                ? '...'
                : ''
            }`
          );
        }
      }
    }
    return 'Category';
  }

  return (
    <BtnGroup role="group" isRCPortal={isRCPortal}>
      {props.name && (
        <BtnWrap
          className={`${props.className} ${selectedCategories ? 'active' : ''}`}
          ref={props.filterRef}
          isRCPortal={isRCPortal}
        >
          {props.slug === 'categories' ? (
            <>
              <Button
                buttonType="light"
                text={
                  props.selectedCategories && props.category
                    ? findNameById(props.selectedCategories, props.category)
                    : props.name
                }
                ButtonClass={
                  selectedCategories
                    ? 'filter-group show-content'
                    : 'filter-group no-category show-content'
                }
                clickHandler={props.onToggle}
                id={`${props.name.replace(/\s+/g, '-')}`}
              />
            </>
          ) : (
            <>
              <Button
                buttonType="light"
                text={
                  props.selectedCategories &&
                  Array.isArray(props.selectedCategories) &&
                  props.selectedCategories.length > 1 &&
                  selectedCategoryName
                    ? `${selectedCategoryName}...`
                    : selectedCategoryName || props.name
                }
                ButtonClass={
                  selectedCategories
                    ? 'filter-group show-content'
                    : 'filter-group no-category show-content'
                }
                ariaLabel={props.name}
                clickHandler={props.onToggle}
                id={`filter-group-btn-${props.name.replace(/\s+/g, '-')}`}
              />
            </>
          )}

          {selectedCategories > 0 && (
            <IconWrap
              id={`remove-selected-category-${props.name.replace(/\s+/g, '-')}`}
              onClick={() => props.removeFilter(props.slug)}
              isRCPortal={isRCPortal}
              aria-label="Remove button"
            >
              <CrossIcon isRCPortal={isRCPortal}>
                <strong>&#10006;</strong>
              </CrossIcon>
            </IconWrap>
          )}
        </BtnWrap>
      )}
      {props.active && (
        <DropdownList className="dropdown" ref={dropdownRef}>
          <DropCatList>
            {Object.entries(props.categoryWithCount).length > 0 ? (
              Object.entries(props.categoryWithCount).map(
                ([category, count]) => (
                  <DropCatListItem key={category}>
                    <DropCatLink
                      onClick={() =>
                        changeHandler(props.slug, category, props.name)
                      }
                    >
                      <>
                        <Checkbox
                          type="checkbox"
                          checked={
                            props.selectedCategories &&
                            props.selectedCategories?.includes(category)
                          }
                          id={`cat-checkbox-${category.replace(/\s+/g, '-')}`}
                          aria-label={category}
                        />
                        {category}
                      </>
                    </DropCatLink>
                  </DropCatListItem>
                )
              )
            ) : (
              <DropCatList>
                {props.category?.map((filter, index) => (
                  <DropCatListItem key={`cat-${index}`}>
                    <DropCatLink
                      onClick={() =>
                        props.handleCategoryFilter(
                          filter,
                          JSON.stringify(filter.category.id),
                          'Parent'
                        )
                      }
                    >
                      <Checkbox
                        type="checkbox"
                        checked={
                          props.selectedCategories &&
                          props.selectedCategories?.includes(
                            JSON.stringify(filter.category.id)
                          )
                        }
                        id={`selectcat-checkbox-${filter.category.name.replace(
                          /\s+/g,
                          '-'
                        )}`}
                      />
                      {filter.category.name}
                    </DropCatLink>
                    {filterDropdown(filter)}
                  </DropCatListItem>
                ))}
              </DropCatList>
            )}
          </DropCatList>
        </DropdownList>
      )}
    </BtnGroup>
  );
};

export default FilterBtnGroup;

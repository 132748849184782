import { AdvanceData, ExperimentViewedEvent } from '@components/shared.types';
import { generateGUID } from '@license-admin/boldfjcomponents';
import { track } from '@utils/eventTrack';
import {
  filterObjData,
  formatDataByViewType,
  formatKeyArrayData,
  formatLocationData,
} from 'helper/eventTrackingUtils';
import {
  Action,
  REGISTRATION_EVENTS,
  SearchType,
  TrackingEvents,
  USER_TRAITS_JOBS_COUNT,
  ViewType,
} from 'helper/eventTrackingUtils/constant';
import {
  formatDateFromISO,
  getClientIdSessionId,
  getISO8601DatesDiff,
  localStorageGetItem,
  localStorageSetItem,
  localStorageSetItemExpiry,
  mapIdTONameOfCategories,
  removeNoEducation,
  replaceUSNational,
} from '..';
import { _CONSTANTS } from '../constant';

export const addOrGetGUID = (
  guid: string | null | undefined,
  cookieKey: string
): string => {
  const searchCookie = cookieKey;

  let searchIdentifierGUID = generateGUID();

  if (guid) {
    // * if guid -> add to localStorage and pass to return
    searchIdentifierGUID = guid;
    localStorageSetItemExpiry(searchCookie, guid, 3);
  } else {
    const searchIdentifier = localStorageGetItem(searchCookie);
    let searchIdentifierValue = searchIdentifier
      ? JSON.parse(searchIdentifier).value
      : null;

    // * check if we have a localstorageValue
    // ** if not, add generated guid into storage
    if (searchIdentifierValue) {
      searchIdentifierGUID = searchIdentifierValue;
    } else {
      localStorageSetItemExpiry(searchCookie, searchIdentifierGUID, 3);
    }
  }
  return searchIdentifierGUID;
};

const commonProperties = (
  guid: string | null | undefined,
  searchType: string | null,
  queryParams: any,
  searchParamTxt: any,
  dataResultCount: any,
  jobsListWithPagination: any,
  viewType: string | null
) => {
  const searchIdentifierGUID = addOrGetGUID(guid, 'search_identifier');
  const searchResultCount = () => {
    if (viewType === ViewType.Separate_Tab) return null;
    if (jobsListWithPagination?.noSearchResult) return '0';

    return dataResultCount > 0
      ? dataResultCount
      : (jobsListWithPagination ? jobsListWithPagination.totalCount : '0') ||
          '0';
  };

  const formatValue = (key: string) => formatKeyArrayData(queryParams?.[key]);
  return {
    'search type': searchType,
    keyword: searchParamTxt ? searchParamTxt : queryParams?.searchkeyword,
    location: queryParams && formatLocationData(queryParams.joblocations),
    'remote work level': formatValue('remoteoptions'),
    'job type': formatValue('jobtypes'),
    'work schedule':
      queryParams && formatKeyArrayData(queryParams.jobschedules),
    'career level': queryParams && formatKeyArrayData(queryParams.careerlevel),
    education: formatValue('educations'),
    company: formatValue('companies'),
    accolades: queryParams && formatKeyArrayData(queryParams.accolades),
    categories: formatValue('categories'),
    travel: queryParams && formatKeyArrayData(queryParams.travels),
    title: formatValue('jobtitles'),
    'search results count': searchResultCount(),
    'search identifier': searchIdentifierGUID,
  };
};

export const jobSearchPerformedTrackEvent = (
  searchType: string | null,
  queryParams: { [key: string]: Array<string> | string | undefined },
  searchParamTxt: string | null,
  dataResultCount: number | null,
  guid: string
) => {
  const eventProps = {
    ...commonProperties(
      guid,
      searchType,
      queryParams,
      searchParamTxt,
      dataResultCount,
      null,
      null
    ),
  };
  const eventName = TrackingEvents.Job_Search_Performed;
  if (
    window &&
    window?.document &&
    !window?.document?.referrer?.includes('searchOptions')
  ) {
    const event = track(eventName, filterObjData(eventProps));
    return event;
  }
};

export const jobSearchResultTrackEvent = async (
  action: string | null,
  searchType: string | null,
  viewType: string,
  jobDetailsObj: any,
  jobsDataObj?: any,
  queryParams?: any,
  clickOption?: string | null,
  jobNumber?: number | null,
  guid?: string | null
) => {
  const jobDetails = structuredClone(jobDetailsObj);
  const jobsData = structuredClone(jobsDataObj);
  const jobsListWithPagination = jobsData?.jobs;
  const jobPostCategories = (jobDetails: any) => {
    const categories =
      jobDetails?.categories || jobDetails?.jobCategories || [];
    if (categories.length > 0)
      return categories.map((item: any) => item.name).join(', ');
    return;
  };

  if (queryParams?.categories) {
    queryParams.categories = mapIdTONameOfCategories(
      jobsData,
      Array.isArray(queryParams.categories)
        ? queryParams.categories
        : [queryParams.categories]
    );
  }

  const currentJob = jobsListWithPagination?.results?.filter(
    (job: any) => job.id === jobDetails.id
  )?.[0];

  const obj = {
    ...commonProperties(
      guid,
      searchType,
      queryParams,
      null,
      null,
      jobsListWithPagination,
      viewType
    ),
    action: action,
    'view type': viewType,
    'click option': clickOption,
    'screen name': 'job search results',
    'job post id': jobDetails?.id,
    'job post title': jobDetails?.title,
    'job post salary': jobDetails?.salaryRange,
    'job post benefits': formatKeyArrayData(jobDetails?.jobBenefits),
    'job post categories': jobPostCategories(jobDetails),
    'job post company': currentJob?.company?.name,
    'job post company id': currentJob?.company?.companyId,
    'job post job type': formatDataByViewType(jobDetails?.jobTypes, viewType),
    'job post remote work level': formatDataByViewType(
      jobDetails?.remoteOptions,
      viewType
    ),
    'job post location':
      jobDetails?.jobLocations &&
      formatDataByViewType(
        replaceUSNational(jobDetails.jobLocations),
        viewType
      ),
    'job post work schedule': formatDataByViewType(
      jobDetails?.jobSchedules,
      viewType
    ),
    'job post career level': formatDataByViewType(
      jobDetails?.careerLevel,
      viewType
    ),
    'job post education':
      jobDetails?.educationLevels &&
      formatDataByViewType(
        removeNoEducation(jobDetails.educationLevels),
        viewType
      ),
    'job post travel': jobDetails?.travelRequired,
    'job post day ago':
      jobDetails?.postedDate && `${getISO8601DatesDiff(jobDetails.postedDate)}`,
    'job post date':
      jobDetails?.postedDate && formatDateFromISO(jobDetails.postedDate),
    'job post page number': jobsListWithPagination?.currentPage,
    'job post page position': jobsListWithPagination && jobNumber,
    'job post actual position':
      jobsListWithPagination &&
      jobNumber &&
      (jobsListWithPagination.currentPage - 1) *
        jobsListWithPagination.resultPerPage +
        jobNumber,
  };

  const eventName = TrackingEvents.Job_Search_Result;
  const event = track(eventName, filterObjData(obj));
  return event;
};

export const jobSearchResultsInteractions = (
  action: string,
  click_option?: string,
  save_search_name?: string,
  guid?: string
) => {
  const obj: any = {
    action: action,
    'screen name': 'job search results',
  };
  if (action === 'save search') {
    obj['save search name'] = save_search_name;
    obj['save search identifier'] = guid;
  } else obj['click option'] = click_option;

  const eventName = TrackingEvents.Job_Search_Result_Interactions;
  const event = track(eventName, obj);
  return event;
};

// export const
export const exitPopUpJoinNow = (page: string) => {
  const { client_id, session_id } = getClientIdSessionId();

  track('join', {
    event_category: 'Join Now Exit Pop Up',
    event_action: 'Join click',
    event_label: page,
    client_id,
    ga4_id: session_id,
  });
};

export const closePopUpEvent = (eventCategory: string) => {
  const { client_id, session_id } = getClientIdSessionId();

  track('close', {
    event_category: eventCategory,
    client_id,
    ga4_id: session_id,
  });
};

export const genericClickEvent = (event: string, properties: any) => {
  track(event, properties);
};

export const registrationClickEvent = (clickOptions: string) => {
  track(REGISTRATION_EVENTS.event_name, {
    action: 'clicked',
    'click option': clickOptions,
  });
};
type reg_event_details_object = {
  action: string;
  popup_name: string;
  screen_name: string;
  click_option?: string;
  isFromWizardPage?: boolean;
};

export const searchRegModalTrackEvent = (
  event_details: reg_event_details_object
) => {
  if (event_details.isFromWizardPage) {
    track('popup', {
      action: event_details.action,
      'popup name': event_details.popup_name,
      'screen name': event_details.screen_name,
      'click option': event_details.click_option,
    });
  }
};

export const navClickEvents = (
  headerType: string,
  headerName: string,
  subHeaderName: string | null = null
) => {
  track('header nav bar clicks', {
    'header type': headerType,
    header: headerName,
    'sub header': subHeaderName,
  });
};

export const errorEvent = (statusCode: number, fromPage?: string) => {
  let props: any = {};
  if (fromPage) {
    props.fromPage = fromPage;
  }
  track(`${statusCode} error`, props);
};

export const companyPageTrackEvent = (
  company_name: string,
  current_jobs: number
) => {
  const eventProps = {
    action: Action.Viewed,
    'company name': company_name,
    'current jobs': current_jobs,
  };
  track('company page', eventProps);
};

export const experimentViewedEvent: ExperimentViewedEvent = (
  expId,
  expName,
  expVariantName,
  referrer
) => {
  const obj = {
    'experiment id': expId,
    'experiment name': expName,
    'experiment variant': expVariantName,
    referrer: referrer,
  };
  const eventName = TrackingEvents.FJ_Exp_Viewed;
  const event = track(eventName, obj);
  return event;
};

export const addJobsCountTraits = (jobsKey: string, increment: number = 1) => {
  const jobCountString = localStorageGetItem(_CONSTANTS.JOBS_COUNT);
  if (jobCountString) {
    const jobCountObj = JSON.parse(jobCountString);

    if (
      jobsKey === USER_TRAITS_JOBS_COUNT.VIEWED_JOBS &&
      jobCountObj[jobsKey] === '100'
    ) {
      // do nothing in case viewed jobs count is 100
    } else if (jobsKey in jobCountObj && !isNaN(jobCountObj[jobsKey])) {
      jobCountObj[jobsKey] = `${parseInt(jobCountObj[jobsKey]) + increment}`;
      localStorageSetItem(_CONSTANTS.JOBS_COUNT, JSON.stringify(jobCountObj));
      track('identify', {
        [jobsKey]: jobCountObj[jobsKey],
      });
    }
  }
};

export const advancedSearchEvent = (advanceData: AdvanceData) => {
  let locationArr = [];
  advanceData?.USLocation && locationArr.push(advanceData?.USLocation);
  advanceData?.interNationalLocation &&
    locationArr.push(advanceData?.interNationalLocation);
  let trackEventProp: any = Object.assign(
    { 'search type': SearchType.Advanced_Search },
    advanceData?.searchkeyword ? { keyword: advanceData?.searchkeyword } : null,
    advanceData?.remoteoptions?.length
      ? { 'remote work level': advanceData?.remoteoptions?.join(', ') }
      : null,
    advanceData?.USLocation ? { USLocation: advanceData?.USLocation } : null,
    advanceData?.interNationalLocation
      ? { interNationalLocation: advanceData?.interNationalLocation }
      : null,
    advanceData?.jobtypes?.length
      ? { 'job type': advanceData?.jobtypes?.join(', ') }
      : null,
    advanceData?.jobschedules?.length
      ? { 'work schedule': advanceData?.jobschedules?.join(', ') }
      : null,
    advanceData?.careerlevel?.length
      ? { 'career level': advanceData?.careerlevel?.join(', ') }
      : null,
    advanceData?.educations ? { education: advanceData?.educations } : null,
    advanceData?.accolades ? { accolades: advanceData?.accolades } : null,
    advanceData?.categories?.length
      ? { categories: advanceData?.categories?.join(', ') }
      : null,
    advanceData?.travels?.length
      ? { travel: advanceData?.travels?.join(', ') }
      : null,
    advanceData?.excludeWords
      ? { 'exclusion words': advanceData?.excludeWords }
      : null,
    advanceData?.anywhereInUS
      ? { anywhereInUS: advanceData?.anywhereInUS }
      : null,
    advanceData?.anywhereInWorld
      ? { anywhereInWorld: advanceData?.anywhereInWorld }
      : null,
    advanceData?.interNationalLocation || advanceData?.USLocation
      ? { location: locationArr.join(', ') }
      : null
  );

  track(TrackingEvents.Job_Search_Performed, trackEventProp);
};

import { Colors, base_font } from '@styles/graphite/styled';

const SearchFilterStyle = {
  Colors: {
    searchButton: Colors.white,
    resultHighlight: Colors.white,
    searchHead: Colors.blue1,
    searchWrap: Colors.blue26,
    searchButtonHover: Colors.white,
  },
  BgColors: {
    search: Colors.white,
    searchResultWrap: Colors.white,
    mbSearch: Colors.white,
    searchButton: Colors.azure,
    searchButtonHover: Colors.blue27,
    headerSearch: Colors.blue4,
    resultHighlight: Colors.blue2,
    resultWrapper: Colors.white,
    listItemBg: Colors.white,
  },
  BdrColors: {
    searchWrap: Colors.gray48,
    searchBtnWrap: Colors.azure,
    transSearch: Colors.black,
    suggestMix: Colors.gray1,
    searchResultWrapBrdr: Colors.gray1,
    staticListBrdr: Colors.gray31,
    searchHeadBrdr: Colors.gray31,
    searchWrapShadow: Colors.shadowSharp,
    resultWrapper: Colors.gray1,
    searchWrapRight: Colors.gray48,
  },
  FontSizes: {
    transButton: base_font * 1.1 + 'rem',
    searchButton: base_font * 1.3 + 'rem',
    listItem: (base_font * 0.875).toFixed(1) + 'rem',
  },
  BdrLeftAdnRight: {
    searchButton: '0',
  },
};
export default SearchFilterStyle;

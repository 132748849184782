import {
  checkIsFJFeatureAllowed,
  readCookie,
  updateJobIdInCookie,
} from '@components/common';
import { IJobCardProps } from '@components/shared.types';
import { useDeviceType } from '@license-admin/boldfjcomponents';
import { ignoreJob } from 'API/ApiHelpers';
import DesktopJobCard from './Desktop';
import MobileJobCard from './Mobile';

const JobCard = (props: IJobCardProps) => {
  const {
    isPremiumUser,
    isLoggedIn,
    targetElement,
    customAttribute,
    setShowFlexJobsDifferent,
    showDetails,
    setJobIndex,
  } = props;
  const {
    id,
    company,
    jobSchedules,
    remoteOptions,
    salaryRange,
    jobTypes,
    isTelecommute,
    isFreeJob = false,
  } = props.jobsData;

  const isMobileView = useDeviceType(props.deviceType) === 'mobile';

  const scrollingTop = () => {
    if (targetElement && targetElement.current) {
      targetElement.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleCardClick = async (e: React.MouseEvent<HTMLElement>) => {
    if (isMobileView) return;

    e.preventDefault();
    // if (props?.jobsData?.expireOn && isExpired(props.jobsData.expireOn)) {
    //   return;
    // }

    if (showDetails) {
      setShowFlexJobsDifferent && setShowFlexJobsDifferent();
      showDetails(e, id, company?.slug);
      if (!isPremiumUser || !isLoggedIn) updateJobIdInCookie(id);
      scrollingTop();
    }
    customAttribute && setJobIndex && setJobIndex(customAttribute);
  };

  const deleteJob = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    const element = document.getElementById(id);

    if (element && element.parentNode) {
      element.parentNode.removeChild(element);
      const userStatus = JSON.parse(readCookie('UserStatus'));
      checkIsFJFeatureAllowed() &&
        ignoreJob(userStatus.User.MemberId, id, document.cookie);
    }
  };

  // Do not show Flexible Schedule pill in job card FJ-930, point 7.
  const jobSchedulesData = jobSchedules
    ? jobSchedules.filter(
        (js) => js && js.toLowerCase() !== 'flexible schedule'
      )
    : [];

  if (jobSchedulesData) {
    let fullTimeIndex = -1;
    let partTimeIndex = -1;
    jobSchedulesData.forEach((schedule, index) => {
      if (schedule) {
        if (schedule.toLowerCase() === 'full-time') {
          fullTimeIndex = index;
        } else if (schedule.toLowerCase() === 'part-time') {
          partTimeIndex = index;
        }
      }
    });
    if (fullTimeIndex !== -1 && partTimeIndex !== -1) {
      jobSchedulesData.splice(
        0,
        0,
        `${jobSchedulesData[fullTimeIndex]}/${jobSchedulesData[partTimeIndex]}`
      );
    }
  }

  let remoteOptionsFilter =
    isTelecommute === false
      ? remoteOptions?.filter(
          (iter) => iter && iter.toLowerCase() != 'option for remote work'
        ) || []
      : remoteOptions;

  const totalTagsCount =
    (Array.isArray(remoteOptions) ? remoteOptions.length : 0) +
    (Array.isArray(jobSchedules) ? jobSchedules.length : 0) +
    (Array.isArray(jobTypes) ? jobTypes.length : 0) +
    (salaryRange && salaryRange.length > 0 ? 1 : 0);

  if (isMobileView) {
    return (
      <MobileJobCard
        handleCardClick={handleCardClick}
        jobSchedulesData={jobSchedulesData}
        remoteOptionsFilter={remoteOptionsFilter}
        totalTagsCount={totalTagsCount}
        deleteJob={deleteJob}
        isFreeJob={isFreeJob}
        customAttribute={customAttribute}
        {...props}
      />
    );
  } else {
    return (
      <DesktopJobCard
        handleCardClick={handleCardClick}
        jobSchedulesData={jobSchedulesData}
        remoteOptionsFilter={remoteOptionsFilter}
        totalTagsCount={totalTagsCount}
        deleteJob={deleteJob}
        isFreeJob={isFreeJob}
        customAttribute={customAttribute}
        {...props}
      />
    );
  }
};

export default JobCard;

import ApplyButton from '@components/common/applyButton';
import JobNotes from '@components/jobNotes';
import SaveJobComponent from '@components/saveJobComponent';
import { JobDetailsResults } from '@components/shared.types';
import { Button } from '@license-admin/boldfjcomponents';
import useMediaQuery from '@utils/useMediaQuery';
import copy from 'copy-to-clipboard';
import { useRouter } from 'next/router';
import {
  FlagWrapper,
  JobActionWrapper,
  JobDetailsButton,
  JobDetailsWrapper,
  RegisteredUserNotPaid,
  RegisteredUserPaid,
  ReportJob,
} from './styled';

type Props = {
  jobId: string;
  isFreeJob?: any;
  slug: string;
  isPremiumUser?: any;
  applyURL: string | null;
  appliedDate: string | null;
  applyJobStatus: string;
  savedJobsCommons: any;
  reportJobStatus: boolean;
  showJobDetailButton?: boolean;
  referer?: string;
  isHostedPage?: boolean;
  applyPopupRef?: React.RefObject<HTMLDivElement>;
  jobDetails?: JobDetailsResults;
  jobNumber?: number;
  jobsData?: any;
  queryParams?: any;
  guid?: string;
  hideNote?: boolean;
  hideCopyReport?: boolean;
  isDetailPage?: boolean;
  localization?: any;
  isHybrid?: boolean;
  isVariant?: boolean;
  isRCPortal?: boolean;
  isLoggedIn?: boolean;
  jobDetailsPage?: boolean;
  hideSaveJobButton?: boolean;
};

const JobInfoFooter = (props: Props) => {
  const {
    jobId,
    slug,
    applyURL,
    applyJobStatus,
    appliedDate,
    reportJobStatus,
    showJobDetailButton,
    isHostedPage,
    hideNote,
    hideCopyReport,
    isDetailPage,
    localization,
    isVariant,
    isRCPortal,
    jobDetailsPage,
    hideSaveJobButton,
  } = props;
  const router = useRouter();

  const copyText = (e: any, jobId: string) => {
    e.preventDefault();
    copy(window.location.origin + `/job-details/${slug}/`);
  };

  const isMobileView = useMediaQuery('(max-width:991px)');
  const goToDashboard = () => {
    window.location.href = '/dashboard';
  };

  const goBack = () => {
    if (props.referer) {
      window.location.href = props.referer;
    } else {
      location.reload();
    }
  };
  const handleReportJobs = (e: any) => {
    const newPathObject = {
      pathname: router.pathname,
      query: { ...router.query, showflag: 1 },
    };
    router.push(newPathObject, undefined, { shallow: true });
    e.preventDefault();
  };

  return (
    <>
      {isDetailPage && (
        <JobActionWrapper isRCPortal={isRCPortal}>
          <ApplyButton
            localization={localization}
            buttonSize={isMobileView ? 'md' : 'lg'}
            jobId={jobId}
            isJobAlreadyApplied={false}
            applyURL={applyURL}
            applyJobStatus={applyJobStatus}
            applyPopupRef={props.applyPopupRef}
            isHostedPage={props.isHostedPage}
            jobDetails={props.jobDetails}
            jobNumber={props.jobNumber}
            jobsData={props.jobsData}
            queryParams={props.queryParams}
            guid={props.guid}
            isDetailPage
            isHybrid={props.isHybrid}
            isLoggedIn={props.isLoggedIn}
          />
          {!hideSaveJobButton && (
            <SaveJobComponent
              jobId={props.jobId}
              {...props.savedJobsCommons}
              isHostedPage={props.isHostedPage}
              jobDetails={props.jobDetails}
              jobNumber={props.jobNumber}
              jobsData={props.jobsData}
              queryParams={props.queryParams}
              guid={props.guid}
              isDetailPage
            />
          )}
          {!jobDetailsPage && (
            <Button
              rel={!props.jobsData?.isFreeJob ? 'nofollow' : undefined}
              url={`/job-details/${slug}/`}
              buttonSize={isMobileView ? 'sm' : 'md'}
              buttonType="transparent"
              text="Copy Link"
              icon="fa-link"
              fontWeight="500"
              clickHandler={(e: any) => copyText(e, jobId)}
              underlineOnHover
              ButtonClass="align-right-btn"
            />
          )}
        </JobActionWrapper>
      )}
      {hideNote && !isRCPortal && (
        <JobNotes
          jobId={jobId}
          applyJobStatus={applyJobStatus}
          appliedDate={appliedDate}
          savedJobsCommons={props.savedJobsCommons}
          isHostedPage={isHostedPage}
          isVariant={isVariant}
        />
      )}
      {hideCopyReport && !isRCPortal && (
        <FlagWrapper>
          {!isDetailPage && (
            <Button
              id="copy-link"
              url={`/job-details/${slug}/`}
              rel={!props.jobsData?.isFreeJob ? 'nofollow' : undefined}
              buttonSize={isMobileView ? 'sm' : 'md'}
              buttonType="transparent"
              text="Copy Link"
              icon="fa-link"
              fontWeight="500"
              clickHandler={(e: any) => copyText(e, jobId)}
              underlineOnHover
              ButtonClass="align-right-btn"
            />
          )}
          {reportJobStatus ? (
            <ReportJob
              reportedJob
              isHostedPage={isHostedPage}
              id="job-reported"
            >
              <i className="fa-solid fa-flag"></i>Job Reported
            </ReportJob>
          ) : isHostedPage ? (
            <ReportJob
              isHostedPage={isHostedPage}
              onClick={handleReportJobs}
              id="flag-job-btn"
            >
              <i className="fa-regular fa-flag"></i>
              {isDetailPage ? 'Report Job' : 'Flag Job'}{' '}
            </ReportJob>
          ) : (
            <ReportJob
              isHostedPage={isHostedPage}
              href={`/job-details/${slug}/`}
              target="_blank"
              id="flag-job-link"
              rel={!props?.jobDetails?.isFreeJob ? 'nofollow' : undefined}
            >
              <i className="fa-regular fa-flag"></i>
              {isDetailPage ? 'Report Job' : 'Flag Job'}
            </ReportJob>
          )}
        </FlagWrapper>
      )}

      {isRCPortal ? (
        jobDetailsPage && !hideSaveJobButton ? (
          <JobDetailsWrapper>
            <Button
              url={`/job-details/${slug}/`}
              buttonSize={isMobileView ? 'sm' : 'md'}
              buttonType="transparent"
              text="Copy Link"
              icon="fa-link"
              fontWeight="500"
              clickHandler={(e: any) => copyText(e, jobId)}
              underlineOnHover
              ButtonClass="align-right-btn"
              rel={!props?.jobDetails?.isFreeJob ? 'nofollow' : undefined}
            />
          </JobDetailsWrapper>
        ) : (
          <JobDetailsWrapper>
            <ApplyButton
              localization={localization}
              buttonSize={isMobileView ? 'md' : 'lg'}
              jobId={jobId}
              isJobAlreadyApplied={false}
              applyURL={applyURL}
              applyJobStatus={applyJobStatus}
              applyPopupRef={props.applyPopupRef}
              isHostedPage={props.isHostedPage}
              jobDetails={props.jobDetails}
              jobNumber={props.jobNumber}
              jobsData={props.jobsData}
              queryParams={props.queryParams}
              guid={props.guid}
              isDetailPage
              isHybrid={props.isHybrid}
              isLoggedIn={props.isLoggedIn}
            />
          </JobDetailsWrapper>
        )
      ) : !isDetailPage && hideSaveJobButton ? (
        <RegisteredUserNotPaid>
          <ApplyButton
            localization={localization}
            buttonSize={isMobileView ? 'md' : 'lg'}
            jobId={jobId}
            isJobAlreadyApplied={false}
            applyURL={applyURL}
            applyJobStatus={applyJobStatus}
            applyPopupRef={props.applyPopupRef}
            isHostedPage={props.isHostedPage}
            jobDetails={props.jobDetails}
            jobNumber={props.jobNumber}
            jobsData={props.jobsData}
            queryParams={props.queryParams}
            guid={props.guid}
            isDetailPage
            isHybrid={props.isHybrid}
            isLoggedIn={props.isLoggedIn}
          />
        </RegisteredUserNotPaid>
      ) : (
        <RegisteredUserPaid>
          <Button
            url={`/job-details/${slug}/`}
            buttonSize={isMobileView ? 'sm' : 'md'}
            buttonType="transparent"
            text="Copy Link"
            icon="fa-link"
            fontWeight="500"
            clickHandler={(e: any) => copyText(e, jobId)}
            underlineOnHover
            ButtonClass="align-right-btn"
            rel={!props.jobsData?.isFreeJob ? 'nofollow' : undefined}
          />
        </RegisteredUserPaid>
      )}
      {showJobDetailButton && (
        <JobDetailsButton>
          {props.referer && (
            <Button
              id="lastpage"
              buttonType="primary"
              text="Back to Last Page"
              icon="fa-angle-left"
              underlineOnHover
              clickHandler={goBack}
            ></Button>
          )}
          <Button
            id="dashboard"
            buttonType="primary"
            text="Back to My Dashboard"
            isIconRight
            icon="fa-angle-right"
            underlineOnHover
            clickHandler={() => goToDashboard()}
          />
        </JobDetailsButton>
      )}
    </>
  );
};

export default JobInfoFooter;

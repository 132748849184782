import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const ListWrap = styled.ul<{
  isPremium?: boolean;
  isHybrid?: boolean;
  isRCPortal?: boolean;
}>`
  list-style: none;
  padding: ${({ isRCPortal }) =>
    isRCPortal ? '2.4rem 0 0.8rem' : '0 0 0.8rem'};
  margin: 0;
  ${({ theme: { JobInfoStyle } }) =>
    JobInfoStyle &&
    css`
      color: ${JobInfoStyle.Colors.listWrapper};
    `}
  .mob_nonpremium_banner {
    margin-bottom: 1.2rem;
    .unlock-lock {
      inset: -5rem auto auto 23rem;
      max-height: 2rem;
    }
    ${device.mobileLg} {
      margin-bottom: 4rem;
    }
  }
  ${device.mobileLg} {
    ${({ isPremium, isHybrid }) =>
      !(isPremium || isHybrid) && `h5{font-weight:400} p{font-weight:600}`}
  }
`;
export const JobSummary = styled.p`
  ${device.mobileLg} {
    padding-bottom: 1.2rem;
    margin-bottom: 0;
    ${({ theme: { JobInfoStyle } }) =>
      JobInfoStyle &&
      css`
        border-bottom: 0.05rem solid ${JobInfoStyle.BdrColors.jobSummary};
      `}
  }
`;

export const ListItem = styled.li<{
  isHostedPage?: boolean;
  isRCPortal?: boolean;
}>`
  display: grid;
  grid-template-columns: 15rem 1fr;
  gap: ${({ isRCPortal }) => isRCPortal && css`2.4rem`};
  padding: ${({ isHostedPage, isRCPortal }) =>
    isRCPortal ? '0 0 0.8rem' : isHostedPage ? '0.4rem' : '0 0.4rem 0.85rem'};
  ${device.mobileLg} {
    grid-template-columns: 16rem 1fr;
  }
`;

export const Title = styled.p<{ isRCPortal?: boolean }>`
  margin: 0;
  line-height: 1.5;
  ${({ theme: { JobInfoStyle }, isRCPortal }) =>
    JobInfoStyle &&
    css`
      ${isRCPortal &&
      css`
        font-size: ${JobInfoStyle?.FontSizes?.jobInfoTitle};
        font-family: ${JobInfoStyle?.FontFamily?.jobInfoTitle};
      `}
      color: ${JobInfoStyle.Colors.title};
      font-weight: ${JobInfoStyle.FontWeight.title};
    `};
`;

export const Detail = styled.p<{ isRCPortal?: boolean }>`
  margin: 0 0.2rem 0 0;
  word-break: break-word;
  ${Mixin.VerticalCenter({ wrap: 'wrap', gap: ' 0 0.2rem' })};
  ${({ theme: { JobInfoStyle }, isRCPortal }) =>
    JobInfoStyle &&
    css`
      color: ${JobInfoStyle.Colors.title};
      font-weight: ${isRCPortal
        ? JobInfoStyle?.FontWeight?.descNew
        : JobInfoStyle.FontWeight.desc};
    `};

  &.add-blur {
    filter: blur(0.8rem);
  }
  .icon-us {
    background: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMS44MTNweCIgdmlld0JveD0iMCAwIDE0IDExLjgxMyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTQgMTEuODEzIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHJlY3QgZmlsbD0iI0ZGRkZGRiIgd2lkdGg9IjE0IiBoZWlnaHQ9IjExIi8+CjxyZWN0IGZpbGw9IiNFQTA5MjAiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxIi8+CjxyZWN0IHk9IjIiIGZpbGw9IiNFQTA5MjAiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxIi8+CjxyZWN0IHk9IjQiIGZpbGw9IiNFQTA5MjAiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxIi8+CjxyZWN0IHk9IjYiIGZpbGw9IiNFQTA5MjAiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxIi8+CjxyZWN0IHk9IjgiIGZpbGw9IiNFQTA5MjAiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxIi8+CjxyZWN0IHk9IjEwIiBmaWxsPSIjRUEwOTIwIiB3aWR0aD0iMTQiIGhlaWdodD0iMSIvPgo8cmVjdCBmaWxsPSIjMEUwRTZEIiB3aWR0aD0iOCIgaGVpZ2h0PSI2Ljk1OCIvPgo8L3N2Zz4=)
      no-repeat;
    width: 1.6rem;
    height: 1.4rem;
    display: inline-block;
    margin-left: 0.4rem;
  }
`;

export const FootNote = styled.div`
  margin: 2.4rem 0 0;
  padding: 2.4rem 0 0;
  ${({ theme: { JobInfoStyle } }) =>
    JobInfoStyle &&
    css`
      border-top: 0.1rem solid ${JobInfoStyle.BdrColors.footNote};
    `}
`;

export const Wrapper = styled.div<{
  isVariant?: boolean;
  isRCPortal?: boolean;
}>`
  ${({ isRCPortal }) =>
    isRCPortal
      ? css`
          margin: 3.2rem 0 0;
          padding-top: 0;
        `
      : css`
          margin: 1rem 0;
          padding-top: 1rem;
        `}

  ${({ theme: { JobInfoStyle }, isVariant }) =>
    isVariant &&
    css`
      border-top: 0.5px solid ${JobInfoStyle.Colors.wrapper};
      ${device.mobileLg} {
        border-top: none;
      }
    `};
`;

export const JobDescription = styled.div`
  word-wrap: break-word;
`;

export const JobOveriew = styled.p`
  word-wrap: break-word;
`;

export const Subheading = styled.h2<{ isRCPortal?: boolean }>`
  margin-bottom: ${({ isRCPortal }) =>
    css`
      ${isRCPortal ? '1.6rem' : '1.2rem'}
    `};
  i {
    margin-right: 1rem;
  }
  ${({ isRCPortal, theme: { JobInfoStyle } }) =>
    isRCPortal &&
    css`
      font-size: ${JobInfoStyle?.FontSizes?.subHeading};
      font-weight: ${JobInfoStyle?.FontWeight?.descNew};
    `}
`;

export const ContentWrap = styled.div<{
  isDetailPage?: boolean;
  isPadding?: boolean;
}>`
  padding: ${({ isDetailPage }) => (isDetailPage ? '1.2rem 0 0 0' : '0')};
  ${({ isPadding }) =>
    isPadding &&
    css`
      ${device.mobileLg} {
        padding-bottom: 10rem;
      }
    `}
`;

export const UnAuthFooterText = styled.div`
  padding-top: 2.4rem;
  margin-top: 2.4rem;
  ${({ theme: { JobInfoStyle } }) =>
    JobInfoStyle &&
    css`
      border-top: 0.1rem solid ${JobInfoStyle.BdrColors.unAuthFootbrdr};
    `}
`;
export const UnAuthJobTitle = styled.strong<{ blurText?: boolean }>`
  ${({ blurText }) =>
    blurText &&
    css`
      filter: blur(0.8rem);
    `}
`;
export const DatailWrap = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;
export const JobLocationsWrap = styled.div<{
  isPremium?: boolean;
  isHybrid?: boolean;
  isHostedPage?: boolean;
  isRCPortal?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  ${device.mobileLg} {
    ${({ isPremium, isHybrid }) =>
      !(isPremium || isHybrid) && `font-weight:600`}
  }
  ${({ theme: { JobInfoStyle }, isRCPortal }) =>
    JobInfoStyle &&
    css`
      font-weight: ${isRCPortal
        ? JobInfoStyle.FontWeight.descNew
        : JobInfoStyle.FontWeight.desc};
    `};
  img {
    align-self: center;
    margin: 0 0 0 0.2rem;
  }
`;

export const AboutAccoladeWrap = styled.div`
  margin: 2rem 0;
`;

export const AboutCompTitle = styled.h2`
  margin-bottom: 1.6rem;
`;

export const AccoladeTitle = styled.h4`
  color: #343a40;
  margin-bottom: 1.2rem;
  font-weight: 600;
`;

export const AboutComWrapper = styled.div`
  border: 1px solid #bfbfbf;
  background: #f8fafb;
  padding: 2.4rem 2.4rem 0;
  border-radius: 0.4rem;
  margin-bottom: 1.6rem;
`;

export const LearnCTAWrap = styled.div`
  border-top: 0.1rem solid #bfbfbf;
  padding: 1.2rem 0 1.2rem;
  text-align: center;
  margin: 0 -2.4rem;
`;

export const AboutCompName = styled.h3`
  margin-bottom: 0;
  font-weight: 600;
  color: #343a40;
`;

export const OverviewDesc = styled.div`
  ${Mixin.Ellipsis({ row: 4 })}
  margin-bottom: 1.4rem;
`;

export const ContactLink = styled.a``;

import { ContentBox, JobActionsWrapper } from '@components/jobsSection/styled';
import { Button, FormGroup } from '@license-admin/boldfjcomponents';
import JobsActionLink from './jobsActionLink';
import {
  ButtonLg,
  CheckBoxWrap,
  FormMessage,
  FormWrapper,
  LinkList,
} from './styled';

const SocialLink = ({ isRCPortal }: any) => {
  return (
    <LinkList isRCPortal={isRCPortal}>
      <li>Follow Us:</li>
      <li>
        <a
          href="https://www.facebook.com/remote.co"
          target="_blank"
          aria-label="facebook"
          rel="noreferrer"
        >
          <i className="fab fa-facebook-f" aria-hidden="true"></i>
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/remote_co"
          target="_blank"
          aria-label="twitter"
          rel="noreferrer"
        >
          <i className="fa-brands fa-x-twitter" aria-hidden="true"></i>
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/remote-co/"
          target="_blank"
          aria-label="linkdin"
          rel="noreferrer"
        >
          <i className="fab fa-linkedin-in" aria-hidden="true"></i>
        </a>
      </li>
    </LinkList>
  );
};
const NewLetterForm = () => {
  return (
    <FormWrapper>
      <FormMessage>Sign Up for Our Weekly Fresh Jobs Newsletter</FormMessage>
      <FormGroup
        id="f_name"
        type="text"
        value=""
        label={'First Name '}
        isFormSecondary
        isSpacing
      />
      <FormGroup
        id="email-addresss"
        type="email"
        value=""
        isRequired={true}
        label="Email Address"
        isFormSecondary
        isSpacing
      />
      <FormMessage isCheckBoxHead>What types of jobs?</FormMessage>
      <CheckBoxWrap>
        <li>
          <input type="checkbox" id="label-general" />
          <label htmlFor="label-general">General</label>
        </li>
        <li>
          <input type="checkbox" id="label-technology" />
          <label htmlFor="label-technology">Technology</label>
        </li>
      </CheckBoxWrap>

      <Button
        text="Subscribe"
        btnType="submit"
        id="btn-bubscribe"
        ButtonClass="btn-submit"
      />
    </FormWrapper>
  );
};
interface JOBAction {
  isRCPortal?: any;
  isCompanyComponent?: boolean;
  isRemoteJobsPage?: boolean;
  isLoggedIn?: boolean;
}

const JobsAction = (props: JOBAction) => {
  const { isRCPortal, isCompanyComponent, isRemoteJobsPage, isLoggedIn } =
    props;
  const JobActionsInfo = () => {
    return (
      <>
        {!isLoggedIn && isRCPortal && (
          <ContentBox isSecondarySm isRCPortal={isRCPortal}>
            <ButtonLg
              href="/wizard/welcome/"
              id="post-remote-link"
              isRCPortal={isRCPortal}
              rel="nofollow"
            >
              Get Started
            </ButtonLg>
          </ContentBox>
        )}

        <ContentBox
          isSecondarySm
          isCompanyComponent={isCompanyComponent}
          isRCPortal={isRCPortal}
        >
          <JobsActionLink
            isRCPortal={isRCPortal}
            isRemoteJobsPage={isRemoteJobsPage}
          />
          <SocialLink isRCPortal={isRCPortal} />
          {/* <NewLetterForm /> */}
        </ContentBox>
      </>
    );
  };
  return (
    <>
      {isRCPortal ? (
        <JobActionsWrapper>{JobActionsInfo()}</JobActionsWrapper>
      ) : (
        <div>{JobActionsInfo()}</div>
      )}
    </>
  );
};

export default JobsAction;

export const Colors = {
  white: '#ffffff',
  black: '#000000',
  black1: '#212529',
  black2: '#444444',
  black3: '#3E3D3F',
  black4: '#333333',
  black5: '#e7e7e7',
  black6: '#000000d9',
  black7: '#373D3F',
  black8: '#555555',
  blue1: '#004f6d',
  blue2: '#007cad',
  blue3: '#23527c',
  blue4: '#f1f9fe',
  blue5: '#cfe2ff',
  blue6: '#b6d4fe',
  blue7: '#2d67b0',
  blue8: '#C6DAE5',
  blue9: '#00506d',
  blue10: '#f4f9fa',
  blue11: '#0a58ca',
  blue12: '#86b7fe',
  blue13: '#084298',
  blue14: '#0d6efd',
  blue15: '#009eeb',
  blue16: '#0d6efd33',
  blue17: '#5897fb',
  blue18: '#E8EDF2',
  blue19: '#06357a',
  blue20: '#f9fbfe',
  blue21: '#80bed6',
  blue22: '#343A40',
  blue23: '#f1f9fe80',
  blue24: '#005170',
  blue25: '#7c8a9d',
  blue26: '#002D6B',
  blue27: '#004975',
  blue28: '#00a2e1',
  blue29: 'rgb(0, 110, 165)',
  gray1: '#cccccc',
  gray2: '#d8d8d8',
  gray3: '#dee2e6',
  gray4: '#979797',
  gray5: '#e8edf1',
  gray6: '#6c757d',
  gray7: '#ced4da',
  gray8: '#e9ecef',
  gray9: '#798f9d',
  gray10: '#b2b3b3',
  gray11: '#dadada',
  gray12: '#eeeeee',
  gray13: '#f5f5f5',
  gray14: '#d4d4d4',
  gray15: '#00000040',
  gray16: '#f0f0f0',
  gray17: '#f3f4fa',
  gray18: '#656565',
  gray19: '#7f7f7f',
  gray20: '#d9d9d9',
  gray21: '#E7EDF3',
  gray22: '#666',
  gray23: '#0000001f',
  gray24: '#ddd',
  gray25: '#999',
  gray26: '#e6e6e6',
  gray27: '#00000020',
  gray28: '#bfc7d1',
  gray29: '#0000004d',
  gray30: '#00000030',
  gray31: '#e1e1e1',
  gray32: '#e8e8e8',
  gray33: '#6c758e',
  gray34: '#818181',
  gray35: '#bfbfbf80',
  gray36: '#f4f8fa',
  gray37: '#BFBFBF',
  gray38: '#e8edf2bf',
  gray39: '#8e8d8f',
  gray40: '#f3f6f8',
  gray41: '#cacaca',
  gray42: '#e5e5e5',
  gray43: '#0000000d',
  gray44: '#e3e3e3',
  gray45: '#aaaaaa',
  gray46: '#4c4c4c',
  gray47: '#f8f9fa',
  gray48: '#919397',
  gray49: '#e4ecf0',
  gray50: '#d9dadb',
  gray51: '#646F82',
  gray52: '#00000026',
  gray53: '#d9dadb',
  orange1: '#ff532a',
  orange2: '#ff6843',
  orange3: '#fe6843',
  orange4: '#fe5329',
  orange5: '#F3603C',
  red1: '#a82824',
  red2: '#f8d7da',
  red3: '#f5c2c7',
  red4: '#ff0000',
  red5: '#dc3545',
  red6: '#d2322d',
  green1: '#198754',
  green2: '#d1e7dd',
  green3: '#2d4821',
  green4: '#badbcc',
  shadowyBlack: 'rgba(0, 0, 0, 0.13)',
  shadowyBlack2: 'rgba(0, 0, 0, 0.03)',
  shadowyBlack3: 'rgba(0, 0, 0, 0.3)',
  shadowyBlack4: 'rgba(0, 0, 0, 0.125)',
  shadowyBlack5: 'rgba(13, 110, 253, 0.25)',
  shadowyBlack6: 'rgba(0, 0, 0, 0.15)',
  shadowyBlack7: 'rgba(0, 0, 0, 0.1)',
  shadowLight: 'rgb(247 250 253 / 75%)',
  shadowLighter: 'rgb(0 0 0 / 7.5%)',
  shadowyLightest: 'rgba(0, 0, 0, 0.4)',
  shadowSharp: 'rgba(0,0,0,0.25)',
  border3: 'rgb(13 110 253 / 25%)',
  shadowyWhite: 'rgba(255, 255, 255, 0.9)',
  shadowSharpWhite: 'rgba(255,255,255,0.5)',
  inputBorder: 'rgba(145, 147, 151, 1)',
  facebook: '#1877f2',
  linkedin: '#2867b2',
  twitter: '#00acee',
  youtube: '#f00',
  pinterest: '#e60023',
  instagram: '#000000',
  tiktok: '#000000',
  reddit: '#ff4500',

  //NEWLY GRANITE
  azure: '#006EA5',
  lightgreen: '#d5ffc8',
  lightgreen1: '#67c75a',
  lightgreen2: '#4ebb40',
  lightgreen3: '#4AB23C',
  lightgreen4: 'rgb(213, 255, 200)',
  deepOcean: '#006b95',
  darkGray: '#777',
  blackEclipseGray: '#363839',
  skyBlue: '#62d3ff',
  stormGray: '#495057',
  frostedBlue: 'rgba(0,162,225,.25)',
  frostedBlack: 'rgba(0,0,0,.125)',
  brightColor: '#dee2e6',
  lightgray: '#e5f5fc',
};

export const base_font = 1.6;

export const base_font_mb = 1.4;

export const FontSizes = {
  h1: (base_font * 1.75).toFixed(1) + 'rem', //2.8rem
  h2: (base_font * 1.5).toFixed(1) + 'rem', //2.4rem
  h3: (base_font * 1.25).toFixed(1) + 'rem', //2rem
  h4: (base_font * 1.125).toFixed(1) + 'rem', //1.8rem
  h5: base_font + 'rem', //1.6rem
  h6: (base_font * 0.875).toFixed(1) + 'rem', //1.4rem
  p: base_font + 'rem', //1.6rem
};

export const LineHeight = {
  normal: 1.2,
  md: base_font * 1.25 + 'rem',
  lg: base_font * 1.5 + 'rem',
  xl: 1.5,
  xxl: 2,
};

export const FontWeight = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  strong: 900,
};

export const BorderRadius = {
  none: '0',
  small: '0.2rem',
  medium: '0.4rem',
  large: '0.8rem',
  full: '50%',
};

export const FontFamily = {
  fontAwesome: '"Font Awesome 6 Pro"',
  fontFamilyRaleway: 'Raleway,"Open Sans", Arial, sans-serif',
  fontFamilyOpensense: '"Open Sans",Raleway, Arial, sans-serif',
};

export const Icons = {
  suitcase: '"\f0f2"',
  rectanglelist: '"\f022"',
  newspaper: '"\f1ea"',
  location: '"\f3c5"',
  flag: '"\f11d"',
  world: '"\f57e"',
  pluscircle: '"\f055"',
  edit: '"\f044"',
  angleright: '"\f105"',
  angleup: '"\f106"',
  angledown: '"\f107"',
  angleLeft: '"\f104"',
  chevrondown: '"\f107"',
  chevronup: '"\f106"',
  quotesleft: '"\f10d"',
  quotesright: '"\f10e"',
  chevrondownfill: '"\f0d7"',
  clockRotateLeft: "'\f1da'",
  tickicon: "'\f00c'",
  circlecross: "'\f05c'",
};

export const FilterGrayScale = {
  active: 1,
  inActive: 0,
};

export const blob_path = `${process.env.NEXT_PUBLIC_CONTENT_URL}/images`;

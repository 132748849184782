import DesktopPremium from '@components/jobDetails/Desktop/desktopPremium';
import DesktopVisitor from '@components/jobDetails/Desktop/desktopVisitor';
import { IJobDetailsDeviceProps } from '@components/shared.types';

const DesktopJobDetails = (props: IJobDetailsDeviceProps) => {
  if (props.isPremiumUser || props?.isFreeJob) {
    //Desktop Premium
    return <DesktopPremium {...props} hideSaveJobButton={props?.isFreeJob} />;
  } else {
    //Desktop Visitor
    return <DesktopVisitor {...props} />;
  }
};

export default DesktopJobDetails;

import DesktopPremium from '@components/jobCard/Desktop/desktopPremium';
import DesktopVisitor from '@components/jobCard/Desktop/desktopVisitor';
import { IJobCardDeviceProps } from '@components/shared.types';

const DesktopJobCard = (props: IJobCardDeviceProps) => {
  if (props?.isFreeJob || props.isPremiumUser) {
    //Desktop Premium
    return <DesktopPremium {...props} />;
  } else {
    return <DesktopVisitor {...props} />;
  }
};

export default DesktopJobCard;

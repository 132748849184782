import { base_font, Colors, FontFamily } from '@styles/graphite/styled';

const JobInfoStyle = {
  Colors: {
    listWrapper: Colors.black1,
    title: Colors.black1,
    wrapper: Colors.gray37,
  },
  BdrColors: {
    listWrapper: Colors.gray2,
    footNote: Colors.gray1,
    unAuthFootbrdr: Colors.gray32,
    jobSummary: Colors.gray37,
  },
  FontWeight: {
    title: 400,
    desc: 600,
    descNew: 700,
  },
  FontSizes:{
    jobInfoTitle:base_font + 'rem',
    jobInfoTitlesm:(base_font*0.875).toFixed(1) + 'rem',
    subHeading:(base_font*1.25) + 'rem',
  },
  FontFamily:{
    jobInfoTitle:FontFamily.fontFamilyOpensense,
  }
};

export default JobInfoStyle;

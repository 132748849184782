import {
  ContentWrapper,
  MessageContent,
} from '@components/jobInfoFooter/styled';
import { JobDetailsResults } from '@components/shared.types';
import { Button, Popup } from '@license-admin/boldfjcomponents';
import { applyJob } from 'API/jobs';
import { isRCPortal } from 'helper';
import { getSearchType, getViewType } from 'helper/eventTrackingUtils';
import {
  Action,
  ClickOption,
  USER_TRAITS_JOBS_COUNT,
} from 'helper/eventTrackingUtils/constant';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { ApplyJobStatus } from '../constant';
import {
  addJobsCountTraits,
  jobSearchResultTrackEvent,
} from '../event-tracker';
import { MailBtn, PopupFooter, TextWrap, Title } from './styled';

type Props = {
  jobId: string;
  isJobAlreadyApplied: boolean;
  applyURL: string | null;
  applyPopupRef?: React.RefObject<HTMLDivElement>;
  isHostedPage?: boolean;
  jobDetails?: JobDetailsResults;
  jobNumber?: number;
  jobsData?: any;
  applyJobStatus?: string;
  queryParams?: any;
  guid?: string;
  isHybrid?: boolean;
  buttonSize?: string;
  localization?: any;
  isDetailPage?: boolean;
  isLoggedIn?: boolean;
};

const ApplyButton = (props: Props) => {
  const {
    jobId,
    applyURL = '',
    applyJobStatus,
    buttonSize,
    localization,
    isLoggedIn,
    isDetailPage,
  } = props;
  const router = useRouter();

  const [showAppliedPopup, setShowAppliedPopup] = useState<boolean>(false);

  const handleApplyButtonClick = () => {
    if (applyURL) {
      setShowAppliedPopup(true);
    }
    const mergerdQueries = {
      ...props?.queryParams,
      ...router.query,
    };
    jobSearchResultTrackEvent(
      Action.Clicked,
      getSearchType(),
      getViewType(props.isHostedPage),
      props.jobDetails,
      !props.isHostedPage && props.jobsData,
      mergerdQueries,
      ClickOption.Apply,
      props.jobNumber,
      props.guid
    );
  };

  const closeApplyJobPopup = () => {
    setShowAppliedPopup(false);
  };

  const handleApplyJob = () => {
    addJobsCountTraits(USER_TRAITS_JOBS_COUNT.JOB_APPLICATIONS);
    applyJob(jobId, ApplyJobStatus.applied);
    closeApplyJobPopup();
    const mergerdQueries = {
      ...props?.queryParams,
      ...router.query,
    };
    jobSearchResultTrackEvent(
      Action.Applied,
      getSearchType(),
      getViewType(props.isHostedPage),
      props.jobDetails,
      !props.isHostedPage && props.jobsData,
      mergerdQueries,
      null,
      props.jobNumber,
      props.guid
    );
  };

  const checkForApplyURL = () => {
    if (!applyURL) {
      return {
        url: `/members/flexers/jobApply/${jobId}`,
        text: 'Apply for This Job',
      };
    } else if (
      applyJobStatus?.toLowerCase() === ApplyJobStatus.applied.toLowerCase()
    ) {
      return {
        url: isRCPortal
          ? `/dashboard/?tab=applied-jobs`
          : `/members/jobapplications`,
        text: 'Apply',
      };
    } else {
      return {
        url: applyURL,
        text: 'Apply',
      };
    }
  };

  return (
    <>
      {applyURL && applyURL?.includes('@') && !applyURL?.includes('http') ? (
        <MailBtn
          id={`apply-btn`}
          href={`mailto:${applyURL}`}
          isHostedPage={props.isHostedPage}
        >
          <TextWrap isHostedPage={props.isHostedPage}>
            <Title isHostedPage={props.isHostedPage}>
              {localization.to_apply_send}
            </Title>{' '}
            {applyURL}
          </TextWrap>
        </MailBtn>
      ) : (
        <Button
          {...checkForApplyURL()}
          id={`apply-btn`}
          clickHandler={handleApplyButtonClick}
          rel="noreferrer external nofollow"
          openNewTab={true}
          underlineOnHover
          ButtonClass="apply-btn-job save-job-btn"
          buttonType={isDetailPage ? 'primary' : 'tertiary'}
          buttonSize={buttonSize}
          icon={props.isHybrid ? 'fa-share-square' : ''}
        />
      )}

      {/* No Need to show popup when applyURL is not available */}
      {showAppliedPopup && applyURL && isLoggedIn && (
        <>
          <Popup
            heading={`Apply for This Job`}
            closeModal={() => setShowAppliedPopup(false)}
            modalSize="md"
            popUpRef={props.applyPopupRef}
          >
            <div>
              <ContentWrapper>
                <MessageContent>
                  {localization.apply_popup_content}
                </MessageContent>
              </ContentWrapper>

              <PopupFooter>
                <Button
                  text={`${localization.applied_for_job}`}
                  clickHandler={handleApplyJob}
                />
                <Button
                  text={localization.did_not_apply}
                  clickHandler={closeApplyJobPopup}
                />
              </PopupFooter>
            </div>
          </Popup>
        </>
      )}
    </>
  );
};

export default ApplyButton;

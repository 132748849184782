import { checkIsServerCall } from '@components/common';
import { ApiBaseUrl, ApiVersion } from '@components/common/constant';
import { ApiEndPoint } from '@components/shared.types';
import { logError } from 'API/ApiHelpers';

const BASE_URL_EBV1 = process.env.NEXT_PUBLIC_EB_URL + '/v1';
export const activeTheme = process.env.NEXT_PUBLIC_THEME;
export const isRCPortal = process.env.NEXT_PUBLIC_ENABLE_FJ_FEATURE == 'false';
export const blob_img_path = process.env.NEXT_PUBLIC_CONTENT_URL + '/images/';
export const siteLogo = isRCPortal ? 'Rco_logo_2x.png' : 'fj-logo.svg';
export const portalLogo = isRCPortal
  ? 'remote-co-logo.svg'
  : 'logo-fj-sm-rev2.svg';
export const isPortalContent = isRCPortal ? 'RemoteCo' : 'FlexJobs';
export const isPPPageLink = isRCPortal
  ? '/privacy-policy'
  : '/PrivacyPolicy.aspx';
export const isLowerEnv =
  process.env.NEXT_PUBLIC_APP_ENV !== 'prod' &&
  process.env.NEXT_PUBLIC_APP_ENV !== undefined;
export const isProdEnv =
  process.env.NEXT_PUBLIC_APP_ENV == 'prod' &&
  process.env.NEXT_PUBLIC_APP_ENV !== undefined;
export const isValidJSON = (obj: any) => {
  try {
    JSON.stringify(obj);
    return true;
  } catch (error) {
    console.error('Invalid object for JSON.stringify:', error);
    return false;
  }
};
export const getApiUrl = (
  apiBaseUrl: (typeof ApiBaseUrl)[keyof typeof ApiBaseUrl],
  apiEndPoint: ApiEndPoint,
  version: ApiVersion
) => {
  if (!apiBaseUrl) {
    apiBaseUrl = ApiBaseUrl.BASE_URL_RJ;
  }
  apiBaseUrl = checkIsServerCall(apiBaseUrl);
  return `${apiBaseUrl}/${version}${apiEndPoint}`;
};

export const updateUserSettings = async (props: any, config: any) => {
  const { userId, first_name, last_name, email } = props;
  let userDetails = {
    user_uid: userId,
    is_active: true,
    first_name: first_name,
    last_name: last_name,
    product_cd: config.productCD,
    portal_cd: config.portalCD,
    portal_id: config.portalID,
    country_cd: 'US',
  };
  let newUser = false;
  if (email) {
    let emailObj = {
      email: email,
      user_name: email,
    };
    userDetails = { ...userDetails, ...emailObj };
    newUser = true;
  }

  return fetch(ApiBaseUrl.BASE_URL_EB + `/v2/users/${userId}/${newUser}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: userDetails,
    }),
  })
    .then((resp) => {
      if (resp.ok) return resp.json();
    })
    .then((resp) => resp)
    .catch((err) => {
      logError(
        'RMC RMC_JOBS Client Error While Updating User Settings',
        JSON.stringify(err),
        false
      );
      return err;
    });
};

export const updateAddress = async (props: any) => {
  const {
    userId,
    first_name,
    last_name,
    zipCode,
    phoneNumber,
    addressId = '',
  } = props;
  return fetch(`${BASE_URL_EBV1}/user/${userId}/address`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      address_id: addressId,
      addressType: 'home',
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateProvince: '',
      countryCd: 'IS',
      postalCode: zipCode,
      firstName: first_name,
      lastName: last_name,
      contactNumber: phoneNumber,
      alternateNumber: '',
    }),
  })
    .then((resp) => {
      if (resp.ok) return resp.json();
    })
    .then((resp) => resp);
};

export const getUserSettings = async (userId: string, cookie?: any) => {
  return fetch(`${BASE_URL_EBV1}/users/${userId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      cookie: cookie,
      Accept: 'application/json',
    },
  })
    .then((resp) => {
      if (resp.ok) return resp.json();
    })
    .then((resp) => resp);
};

import {
  BorderRadius,
  Colors,
  FontSizes,
  FontWeight,
  base_font,
  base_font_mb,
} from '@styles/graphite/styled';

const RegisterUserStyle = {
  Colors: {
    btn: Colors.white,
    subHeading: Colors.black1,
    error: Colors.red5,
    mobileLabel: Colors.gray6,
    flexJobBenifits: Colors.black1,
  },
  BgColors: {
    bgWhite: Colors.white,
    btn: Colors.orange2,
    form: Colors.gray47,
    formboxshadow:Colors.shadowyBlack6,
  },
  FontSize: {
    btn: FontSizes.h3,
    mobilebtn: FontSizes.h6,
    subHeading: (base_font * 1.375).toFixed(1) + 'rem',
    heading: base_font + 'rem',
    mobileLabel: (base_font * 0.81).toFixed(1) + 'rem',
    existingUser: base_font_mb + 'rem',
    listItem: base_font_mb + 'rem',
    benifitTitle: base_font + 'rem',
    inputLabel: (base_font * 0.875).toFixed(1) + 'rem',
    bottomDescWrapper: (base_font * 0.875).toFixed(1) + 'rem',
    signUpHeading: (base_font * 2.5).toFixed(1) + 'rem',
    signUpSubHeading: (base_font * 0.875).toFixed(1) + 'rem',
    signUpMobileHeading: (base_font * 1.5).toFixed(1) + 'rem',
  },
  FontWeight: {
    label: FontWeight.normal,
    subHeading: FontWeight.medium,
    btn: FontWeight.medium,
  },
  LineHeight: {
    subHeading: base_font * 1.75 + 'rem',
    btn: base_font * 1.75 + 'rem',
  },
  BorderRadius: {
    btn: BorderRadius.small,
  },
  BdrColors: {
    wrapperBrdr: Colors.gray53,
    iconWrapBrdr: Colors.gray53,
    inputBrdr: Colors.inputBorder,
  },
};

export default RegisterUserStyle;

import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const JobsContentWrap = styled.div<{ isMobileView?: boolean }>`
  ${({ isMobileView }) =>
    Mixin.GridLayout({
      gridDimension: isMobileView ? '100%' : '1fr 29.8rem',
      columnGap: isMobileView ? '0' : '1.5rem',
    })}
`;
export const ContentBox = styled.div<{
  isSecondaryBox?: boolean;
  isSecondarySm?: boolean;
}>`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      background-color: ${JobCardStyle?.BgColors?.contentBox};
      border: 0.1rem solid ${JobCardStyle?.BdrColors?.contentBox};
    `}

  padding: ${({ isSecondaryBox, isSecondarySm }) =>
    isSecondaryBox ? '2rem 2rem 0.8rem' : isSecondarySm ? '1.6rem' : '2rem'};
  margin-bottom: 1.6rem;
`;
export const JobsHeading = styled.h2<{ isRCPortal?: boolean }>`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      font-family: ${JobCardStyle?.FontFamily?.JobsHeading};
    `}

  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin: 0 0 1.6rem;
  a {
    ${({ theme: { JobCardStyle } }) =>
      JobCardStyle &&
      css`
        color: #4c4c4c;
        color: ${JobCardStyle.Colors.heading};
      `}
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
  &:after {
    content: '';
    height: 0.3rem;
    flex-grow: 1;
    ${({ theme: { JobCardStyle } }) =>
      JobCardStyle &&
      css`
        background-color: ${JobCardStyle.BgColors.headingAfter};
      `}

    margin-left: 0.8rem;
  }
  ${device.mobileLg} {
    font-size: 2.338rem;
  }

  ${({ isRCPortal, theme: { JobCardStyle } }) =>
    isRCPortal
      ? css`
          font-size: 2.4rem;
          font-weight: 400;
          line-height: 1.2;
          color: ${JobCardStyle?.Colors?.jobsHeading};
          a {
            color: ${JobCardStyle?.Colors?.jobsHeading};
          }
          &:after {
            background-color: ${JobCardStyle?.BgColors?.jobsHeading};
            height: 0.4rem;
          }
          ${device.mobileLg} {
            font-size: 2.4rem;
            &:after {
              content: none;
            }
          }
        `
      : css`
          font-size: 2.8rem;
          font-weight: 500;
          line-height: 1.2;
          color: #4c4c4c;
        `};
`;
export const JobListWrapper = styled.ul<{ isRCPortal?: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;
  border: ${({ isRCPortal }) =>
    isRCPortal ? null : '0.1rem solid rgba(0, 0, 0, 0.125)'};
  background-color: ${({ isRCPortal }) => (isRCPortal ? null : '#fff')};
`;
export const JobList = styled.li<{ isRCPortal?: boolean }>`
  padding: 1.6rem;
  border-bottom: ${({ isRCPortal }) =>
    isRCPortal ? null : '0.1rem solid rgba(0, 0, 0, 0.125)'};
  position: relative;
  &:not(:last-child) {
    align-items: center;
    ${device.mobileTab} {
      grid-template-columns: 1fr 7rem;
    }
    ${({ isRCPortal, theme: { JobCardStyle } }) =>
      isRCPortal
        ? css`
            padding: 2.4rem;
            margin-bottom: 0.8rem;
            border: 0.1rem solid ${JobCardStyle?.BdrColors?.jobListItems};
            border-radius: 0.4rem;
            background-color: ${JobCardStyle?.BgColors?.jobListItems};
            ${Mixin.GridLayout({
              gridDimension: '10rem 1fr',
              columnGap: '4rem',
              mobDimension: '1fr 7rem',
            })};
            cursor: pointer;
            ${device.mobileTab} {
              grid-template-columns: 1fr;
              padding: 1rem;
            }
          `
        : css`
            ${Mixin.GridLayout({
              gridDimension: '4.5rem 1fr max-content',
              columnGap: '1.6rem',
              mobDimension: '1fr 7rem',
            })};
          `}
  }
  &:last-child {
    margin: 0;
    text-align: ${({ isRCPortal }) => (isRCPortal ? null : 'right')};
    padding: ${({ isRCPortal }) => isRCPortal && 0};
  }
  p {
    margin: 0;
  }
  &:hover:not(:last-child) {
    background-color: #fcfcfc;
  }
`;
export const TopWrapperListItem = styled.div`
  ${device.mobileLg} {
    flex: 0 0 74%;
    margin-right: 1rem;
  }
`;
export const JobLink = styled.a<{ isRCPortal?: boolean }>`
  font-size: ${({ isRCPortal }) => (isRCPortal ? '1.8rem' : '1.6rem')};
  font-weight: 700;
  text-transform: capitalize;
  &:hover {
    text-decoration: none;
  }

  ${({ isRCPortal }) =>
    isRCPortal
      ? css`
          margin-bottom: 0.4rem;
          display: inline-block;
          word-break: break-all;
          &::before {
            content: '';
            ${Mixin.Position({ position: 'absolute', value: '0 0 0 0' })}
          }
        `
      : css`
          &::before {
            content: '';
            ${Mixin.Position({ position: 'absolute', value: '0 0 0 0' })}
          }
        `}
  ${device.mobileLg} {
    display: inline;
    word-break: break-word;
  }
`;
export const TimeWrap = styled.span`
  font-size: 1.12rem;
`;
export const ArrowIcon = styled.span<{
  BtnArrow?: boolean;
  isRCPortal?: boolean;
}>`
  &::after {
    content: '';
    display: inline-block;
    border-width: 0.25rem 0.25rem 0 0;
    ${({ theme: { JobCardStyle }, BtnArrow }) =>
      JobCardStyle && BtnArrow
        ? css`
            border-color: ${JobCardStyle?.BdrColors?.BtnArrowIcon};
          `
        : css`
            border-color: ${JobCardStyle?.BdrColors?.ArrowIcon};
          `}
    border-style: solid;
    height: 0.7rem;
    width: 0.7rem;
    transform: rotate(45deg);
    margin-left: 0.3rem;
  }
`;
export const SeeAllLink = styled.a<{ isRCPortal?: boolean }>`
  font-size: 1.6rem;
  text-transform: capitalize;
  ${({ isRCPortal }) =>
    isRCPortal
      ? css`
          display: block;
          border-radius: 0.4rem;
          padding: 0.7rem;
          margin-top: 1.6rem;
          font-weight: 600;
          ${({ theme: { JobCardStyle } }) =>
            JobCardStyle &&
            css`
              font-family: ${JobCardStyle?.FontFamily?.seeAllLink};
              border: 0.2rem solid ${JobCardStyle.BdrColors.seeAllLink};
              color: ${JobCardStyle.BdrColors.seeAllLink};
            `}
          text-align: center;
        `
      : css`
          font-weight: 400;
          font-family: Raleway, sans-serif;
        `}

  &:hover {
    text-decoration: none;
    ${({ theme: { JobCardStyle } }) =>
      JobCardStyle &&
      css`
        border-color: ${JobCardStyle.BdrColors.seeAllLink};
        color: ${JobCardStyle.BdrColors.seeAllLink};
      `}

    &::after {
      border-color: #006b95;
    }
  }
`;
export const ImgWrapper = styled.span<{ isRCPortal?: boolean }>`
  img {
    ${({ isRCPortal }) =>
      isRCPortal
        ? css`
            width: 9.6rem;
            height: 9.6rem;
            max-height: none;
            max-width: none;
            object-fit: contain;
          `
        : css`
            max-height: 4.6rem;
          `}
  }
`;
export const Badge = styled.span`
  padding: 0.4rem 2.4rem;
  border-radius: 9.9rem;
  background-color: #e4ecf0;
  font-size: 1.4rem;
  font-weight: 400;
`;

export const JobDataList = styled.ul<{ isRCPortal?: boolean }>`
  padding: 0;
  ${({ isRCPortal }) =>
    isRCPortal
      ? css`
          margin-bottom: 1.6rem;
        `
      : css`
          margin: 0;
        `}
  list-style: none;
`;
export const JobDataListType = styled.ul`
  margin: 1.6rem 0 0;
  padding: 0;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  list-style: none;
`;
export const JobListItem = styled.li`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      background-color: ${JobCardStyle.BgColors.JobDescPills};
      color: ${JobCardStyle.Colors.JobDescPills};
      font-size: ${JobCardStyle.FontSizes.JobDescPills};
    `}
  padding: 0.4rem 2.4rem;
  border-radius: 9.9rem;
`;

export const LocationWrap = styled.div`
  margin: 1.6rem 0 0;
  color: rgba(100, 111, 130, 1);
  font-size: 1.4rem;
  span {
    padding-right: 0.8rem;
  }
`;

export const NewTag = styled.span`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      line-height: 1.4;
      background-color: ${JobCardStyle.BgColors.NewJobsTag};
      color: ${JobCardStyle.Colors.NewJobsTag};
      font-size: ${JobCardStyle.FontSizes.NewJobsTag};
      font-weight: ${JobCardStyle.FontWeight.bold};
    `}
  padding: 0.4rem 1rem;
  margin-left: 0.8rem;
`;

export const TopWrapper = styled.div`
  ${Mixin.AllCenter({ justify: 'space-between' })};
`;

export const JobAge = styled.span`
  font-size: 1.4rem;
  line-height: 1.4;
  color: rgba(100, 111, 130, 1);
`;

export const CompanyTitle = styled.h3<{ isRCPortal?: boolean }>`
  font-size: 1.6rem;
  font-family: 'Open Sans';
  color: rgba(33, 37, 41, 1);
  font-weight: 600;
  ${device.mobileLg} {
    word-break: break-word;
    ${({ isRCPortal }) =>
      isRCPortal &&
      css`
        margin-bottom: 0;
      `}
  }
`;

export const CompanyLogoWrap = styled.span`
  padding: 1.2rem 0.6rem;
  display: flex;
  border-radius: 0.3rem;
  border: 0.1rem solid rgb(221, 221, 221);
  max-width: 8.5rem;
  justify-content: center;
`;
